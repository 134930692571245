a {
    cursor: pointer !important;
}

.title {
    color: $mainColor;
    font-weight: normal;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey300;

    &.title-icon {
        &:before {
            content: "";
            background-image: url("https://shopstar.pe/arquivos/invoice.png");
            height: 35px;
            width: 40px;
            display: inline-block;
            vertical-align: top;
            background-repeat: no-repeat;
            background-size: 70%;
            margin-top: 8px;
        }
    }

    &-link {
        margin-left: auto;
        color: $mainColor;
        font-size: 12px;
        text-transform: none;

        &:hover {
            text-decoration: none;
            color: $mainColor;
        }
    }
}

$colors-list: $mainColor $secondColor $thirdColor $fourthColor $facebookColor $wappColor $twitterColor;
$names: "main" "second" "third" "fourth" "facebook" "wapp" "twitter";

@each $current-color in $colors-list {
    $i: index($colors-list, $current-color);

    .btn-outline-#{nth($names, $i)} {
        color: $current-color !important;
        background-color: transparent;
        background-image: none;
        border-color: $current-color !important;

        &:hover {
            color: rgba($current-color, .9) !important;
            background-color: transparent !important;
            border-color: rgba($current-color, .9) !important;
        }
    }

    .btn-#{nth($names, $i)} {
        color: $fullWhite;
        background-color: rgba($current-color, 0.9) !important;
        background-image: none;
        border-color: $current-color !important;

        &:hover {
            color: $fullWhite !important;
            background-color: $current-color !important;
        }
    }

    .alert-#{nth($names, $i)} {
        color: $current-color !important;
        background-color: rgba($current-color, 0.2) !important;
        border-color: rgba($current-color, 0.8) !important;
    }

    .alert-box-#{nth($names, $i)} {
        color: $current-color !important;
        background-color: rgba($current-color, 0.2) !important;
        border-color: rgba($current-color, 0.8) !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 10px 15px;
        border-radius: 47px;
    }

    .#{nth($names, $i)}-text {
        color: $current-color !important;
    }

    .text-#{nth($names, $i)} {
        color: $current-color !important;
    }

    .bgc-#{nth($names, $i)} {
        background-color: $current-color !important;
    }
}

.btn-outline-main {
    &:hover {
        color: rgba(#49bff1, 1) !important;
        background-color: transparent !important;
        border-color: rgba(#49bff1, 1) !important;
    }
}

.text-underline {
    text-decoration: underline !important;
}
