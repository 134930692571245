@import "../../styles/colors/_colors";
@import "../../styles/variables/_variables";

.callback-loader {
    height: 100vh;
    background: #fff;
    color: $mainColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.checkout {
    min-height: 800px;
    @media (min-width: 1500px) {
        .container {
            max-width: 1300px;
        }
    }
    &__step {
        &__email-form {
            padding-top: 45px;
            padding-bottom: 35px;
            max-width: 550px;
            margin: auto;

            @media (max-width: 719px) {
                .form-group {
                    .btn {
                        position: static !important;
                        margin: 20px auto;
                    }
                    .loading-icon {
                        color: $mainColor !important;
                    }
                }
            }

            h4 {
                font-size: 18px;
                color: #232323;
                margin-bottom: 0;
                color: $mainColor;
            }

            p {
                font-size: 14px;
                color: #959595;
                font-family: lato-regular, Lato, sans-serif;
                display: block;
                padding-top: 8px;
                color: $mainColor;
            }

            .start-message {
                background-color: rgba($grey100, 0.3);
                width: 100%;
                max-width: 435px;
                margin: auto;
                .card-body {
                    font-size: 14px;
                    padding: 10px;
                }
            }

            .form-group {
                margin-top: 35px;
                position: relative;

                &.loading {
                    .loading-icon {
                        display: block;
                        color: $fullWhite;
                    }

                    .form-control {
                        cursor: not-allowed;
                        background-color: rgba($lightBlue100, 0.3);
                    }
                }

                .loading-icon {
                    display: none;
                    position: absolute;
                    top: 15px;
                    right: 12px;
                    color: $green400;
                }

                .btn {
                    display: block;
                    height: 44px;
                    line-height: 46px;
                    padding: 0 35px;
                    font-size: 14px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    top: 2px;
                    right: 2px;
                }

                .form-control {
                    font-size: 14px;
                    padding: 13px 14px;
                    border-radius: 0;
                    height: 48px;
                    position: relative;
                    &:focus {
                        border-color: rgba($mainColor, 0.7);
                        box-shadow: none;

                        & + .form-text + .form-icon,
                        & + .form-icon {
                            color: rgba($mainColor, 0.7);
                        }
                    }
                }
            }
        }

        &__dots {
            border-top: 1px solid #eee;
            padding: 39px 0 18px;
            position: relative;

            h4 {
                color: #1e1e1e;
                font-size: 14px;
                text-align: center;
                padding-bottom: 11px;
                margin-top: 0;
            }

            ul {
                position: relative;
                z-index: 100;

                li {
                    line-height: 20px;
                    vertical-align: middle;
                    color: $defaultText;
                    font-size: 12px;

                    i {
                        color: $mainColor;
                        font-size: 6px;
                        margin-right: 4px;
                    }
                }
            }

            .icon-lock {
                background: url(https://shopstar.pe/arquivos/sprite-checkout.png?v=999) 570px -156px;
                height: 100px;
                width: 115px;
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 99;
            }
        }

        &__form {
            .card {
                border: none;
                box-shadow: none;
            }

            h3 {
                font-size: 18px;
            }

            h5 {
                font-size: 15px;
            }

            .address-list {
                .btn {
                    font-weight: bold;
                    min-width: 200px;
                    font-size: 14px;
                    padding-left: 25px;
                    padding-right: 25px;
                    &.text-normal {
                        font-weight: normal;
                    }
                }
                &__item {
                    border: 1px solid $grey300;
                    margin-bottom: 30px;
                    .card-body {
                        padding-left: 50px;
                        position: relative;
                        &:before {
                            content: "";
                            display: block;
                            width: 18px;
                            height: 18px;
                            border: 2px solid $grey400;
                            position: absolute;
                            top: 22px;
                            left: 18px;
                            border-radius: 50%;
                        }

                        &:after {
                            content: "";
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-color: $grey400;
                            position: absolute;
                            top: 26px;
                            left: 22px;
                            border-radius: 50%;
                        }
                        &.selected {
                            &:before {
                                border-color: $mainColor;
                            }
                            &:after {
                                background-color: $mainColor;
                            }
                        }
                        p {
                            margin-bottom: 0px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            p {
                font-size: 13px;
                color: $defaultText;

                &.info-message {
                    color: $mainColor;
                    font-size: 12px;

                    i {
                        border: 1px solid $mainColor;
                        border-radius: 10px;
                        width: 10px;
                        height: 10px;
                        color: $mainColor;
                        display: inline-block;
                        vertical-align: top;
                        margin: 5px 4px 0 0;
                        font-size: 6px;
                        text-align: center;
                        line-height: 8px;
                    }
                }

                a {
                    color: $mainColor !important;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            .cvv-input {
                height: 38px;
            }

            .card-input {
                .form-icon {
                    color: $grey300 !important;
                }

                .form-control {
                    &.valid {
                        border-color: var(--green);
                    }
                    &.invalid {
                        border-color: var(--red);
                    }
                }

                .ibk-client {
                    position: absolute;
                    bottom: 8px;
                    font-size: 14px;
                    left: calc(100% + 10px);
                    width: 300px;
                    &.valid {
                        & + .form-control {
                            border-color: var(--green);
                        }
                    }
                    &.invalid {
                        & + .form-control {
                            border-color: var(--red);
                        }
                    }
                }

                &.valid {
                    .form-icon {
                        color: $green500 !important;
                    }
                }

                label {
                    display: flex;
                }

                .cc-types {
                    margin-left: 20px;
                    width: 180px;
                    .form-check {
                        &.form-check-inline {
                            margin-right: 7px;
                            // background: red !important;
                        }

                        &.visa {
                            .form-check-label {
                                background-position: -125px 58px;
                            }
                        }

                        &.amex {
                            .form-check-label {
                                background-position: -3px 58px;
                            }
                        }

                        &.diners {
                            .form-check-label {
                                background-position: -43px 58px;
                            }
                        }

                        &.mc {
                            .form-check-label {
                                background-position: -84px 58px;
                            }
                        }

                        &-input {
                            display: none;

                            &:checked {
                                & + .form-check-label {
                                    opacity: 1;
                                }
                            }
                        }

                        &-label {
                            background: url(https://shopstar.pe/arquivos/sprite-checkout.png?v=999);
                            background-size: 420px auto;
                            width: 37px;
                            height: 25px;
                            cursor: pointer;
                            opacity: 0.6;
                            line-height: unset;
                        }
                    }
                }
            }

            .form-group {
                margin-bottom: 15px;
                position: relative;

                .cvv-image {
                    background: url(https://shopstar.pe/arquivos/sprite-checkout.png?v=999) -849px 781px;
                    height: 33px;
                    width: 39px;
                    vertical-align: top;
                    display: block;
                    margin-left: 10px;
                }

                .cc-icon {
                    display: inline-block;
                    background: url(https://ibkshopstarsasprod.blob.core.windows.net/images/Site/cc-green.png);
                    width: 27px;
                    height: 17px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &.row {
                    margin-bottom: 20px;

                    div[class^="col"] {
                        align-items: center;
                    }
                }

                label {
                    font-size: 13px;
                    color: #242424;
                    margin-bottom: 1px;
                    font-weight: 500;
                    line-height: 24px;
                    padding: 7px 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer !important;
                }

                small {
                    font-size: 11px;
                }

                .form-control,
                input[type="text"] {
                    border-radius: 4px;
                    font-size: 13px;

                    &:focus {
                        border-color: rgba($mainColor, 0.7);
                        box-shadow: none;
                        outline: none;

                        & + .form-text + .form-icon,
                        & + .form-icon {
                            color: rgba($mainColor, 0.7);
                        }
                    }

                    &.is-invalid {
                        & + .form-icon {
                            display: none;
                        }
                    }
                }

                .form-icon {
                    position: absolute;
                    bottom: 8px;
                    right: 10px;
                    color: $defaultText;
                }

                .form-text {
                    &.text-muted {
                        color: $defaultText;
                        font-size: 11px;
                    }
                }

                .btn {
                    font-weight: bold;
                    min-width: 200px;
                    font-size: 14px;
                    padding-left: 25px;
                    padding-right: 25px;
                    &.text-normal {
                        font-weight: normal;
                    }
                }

                &.billing-check {
                    margin-top: 15px;
                    label {
                        display: block;
                        line-height: 16px;
                        padding: 0 25px;
                        b {
                            font-size: 15px;
                        }
                        span {
                            display: block;
                        }
                    }
                }
            }

            &__tabs {
                .nav {
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;

                    &-item {
                        .nav-link {
                            font-size: 14px;
                            padding: 6px;
                            line-height: 14px;
                            color: $defaultText;

                            span {
                                display: block;
                                font-size: 12px;
                            }

                            &.active {
                                background-color: $mainColor;
                                color: $fullWhite !important;
                            }
                        }
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    .address-locator {
                        h2 {
                            font-size: 12px;
                            margin: 0 0 2px;
                            color: #333;
                        }

                        h3 {
                            color: #999;
                            font-weight: 400;
                            margin: 0;
                            line-height: 1.3;
                            font-size: 12px;
                        }
                    }

                    .pickup-store {
                        .card {
                            border: 1px solid $grey200 !important;
                            border-radius: 6px;

                            .card-body {
                                padding-left: 45px;
                                position: relative;

                                i {
                                    position: absolute;
                                    top: 20px;
                                    left: 15px;
                                    font-size: 24px;
                                    color: $mainColor;
                                }

                                h4 {
                                    font-size: 14px;
                                    margin-bottom: 0px;
                                    color: $mainColor;
                                }

                                p {
                                    width: 100%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-size: 12px;
                                    white-space: nowrap;
                                    color: $grey600;

                                    a {
                                        text-decoration: underline;
                                    }
                                }

                                .btn {
                                    position: absolute;
                                    bottom: 13px;
                                    right: 13px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    .map-marker-icon {
                        background-color: $mainColor;
                        border-radius: 50%;
                        width: 110px;
                        height: 110px;
                        display: block;
                        margin: 30px auto;
                    }

                    .address-locator-actions {
                        .btn {
                            min-width: 220px;
                            background-image: none;
                            background: $mainColor;
                            border-radius: 4px;
                            height: 39px;
                            padding: 10px 0;
                            text-shadow: none;
                            box-shadow: none;
                            border: none;
                            font-size: 14px;
                            margin: 0;
                        }

                        a {
                            font-size: 13px;
                            color: $mainColor;
                            text-decoration: underline;
                            display: inline-block;
                            margin: 18px 0;
                        }
                    }
                }
            }
        }

        &__summary {
            .card {
                border-color: transparent;
                background-color: #e6e6e6;
                border-radius: 5px;
                position: relative;
                z-index: 1000;

                .card-body {
                    padding: 12px 35px 30px;

                    h3 {
                        margin: 14px 0;
                        font-size: 18px;
                        display: flex;
                        align-items: center;

                        a {
                            display: block;
                            margin-left: auto;
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }

                    h4 {
                        color: #1e1e1e;
                        font-size: 16px;
                    }

                    p {
                        color: $defaultText;
                        font-size: 11px;
                    }

                    .btn {
                        font-weight: bold;
                    }
                }
            }

            &__info {
                background-color: $fullWhite;
                display: flex;
                align-items: center;
                padding: 13px 13px 13px 35px;
                border: solid 1px #d7d7d7;

                &__icon {
                    margin-right: 25px;
                    height: 35px;
                    width: 40px;
                    display: block;
                    float: left;

                    &.user {
                        background: url(https://ibkshopstarsasprod.blob.core.windows.net/email-templates/Sprite_Iconos_checkout.png)
                            no-repeat center;
                        background-size: 75px;
                        background-position: -17px -58px;
                    }

                    &.address {
                        background: url(https://ibkshopstarsasprod.blob.core.windows.net/email-templates/Sprite_Iconos_checkout.png)
                            no-repeat center;
                        background-size: 75px;
                        background-position: -17px -489px;
                    }

                    &.delivery {
                        background: url(https://ibkshopstarsasprod.blob.core.windows.net/email-templates/Sprite_Iconos_checkout.png)
                            no-repeat center;
                        background-size: 85px;
                        background-position: -20px -518px;
                    }

                    &.map-marker {
                        background: url(https://shopstar.pe/arquivos/sprite-checkout.png?v=999) -140px -295px;
                    }
                }

                &__content {
                    h5 {
                        font-size: 14px;
                        margin: 0;
                        margin-bottom: 1px;
                    }

                    p {
                        color: $defaultText;
                        font-size: 12px;
                        margin: 0;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    &__summary {
        .card {
            border: none;
            box-shadow: none;
            background-color: #e6e6e6;

            &-header {
                background-color: transparent;
                border: none;
                color: #1e1e1e;
                font-size: 16px;
                font-weight: bold;
            }

            &-body {
                padding: 7px;
            }

            &-footer {
                background-color: transparent;
                border: none;
                padding: 0 30px 15px;

                .btn {
                    font-weight: bold;
                    width: 70%;
                    &[disabled] {
                        background-color: $grey600 !important;
                        border-color: $grey600 !important;
                        cursor: not-allowed;
                    }
                }

                table {
                    tr {
                        td {
                            padding: 8px 0;
                            color: #969696;
                            font-size: 14px;
                            line-height: 15px;
                        }

                        &.total {
                            td {
                                color: #1e1e1e;
                            }

                            .text-right {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        &__items {
            background-color: $fullWhite;
            border-radius: 5px;
            max-height: 320px;
            overflow-x: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            table {
                tr {
                    td {
                        .image {
                            display: block;

                            img {
                                display: block;
                                max-width: 55px;
                                max-height: 55px;
                            }
                        }

                        .name {
                            max-width: 225px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            font-size: 16px;
                        }

                        .out-stock {
                            color: $danger;
                            font-size: 80%;
                            a {
                                font-weight: bold;
                                text-decoration: underline;
                            }
                        }

                        .quantity {
                            font-size: 13px;
                            color: #969696;
                        }

                        &.price {
                            vertical-align: middle;
                            font-size: 16px;

                            span {
                                white-space: nowrap;
                                display: block;
                            }
                        }

                        .additional-item {
                            background-color: $grey200;
                            padding: 0.15rem 0.75rem;
                            font-size: 13px;
                            margin-bottom: 7px;
                            margin-right: 5px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        @media (max-height: 655px) {
            &__items {
                max-height: 159px !important;
            }
        }
    }

    .miles-control {
        h5 {
            font-size: 16px;
        }
        .input-group {
            max-width: 200px;
            &-prepend,
            &-append {
                .btn {
                    min-width: unset;
                    border-color: #ced4da;
                    width: 44px;
                    height: 44px;
                    &:hover {
                        background-color: rgba(#ced4da, 0.5);
                        color: unset;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 1px rgba(108, 117, 125, 0.5);
                    }
                }
            }
            .form-control {
                border-radius: 0;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
            }
        }
        .exchange {
            span {
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    .exchange-total {
        border: none;
        box-shadow: none;
        background-color: #f2f6f8;
        .card-body {
            padding: 10px 18px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

@media (max-width: 479px) {
    .pm-check {
        // width: 100%;
        // margin-left: 0 !important;
        // margin-bottom: 10px;
        max-width: 500px;
        width: 100%;
        margin-bottom: 15px;
        label {
            text-align: center;
        }
    }
    .checkout {
        &__summary {
            &__items {
                .table {
                    tbody {
                        tr {
                            td {
                                .image {
                                    img {
                                        width: 35px;
                                    }
                                }
                                &.name {
                                    font-size: 12px;
                                    h4 {
                                        margin-bottom: 0;
                                        font-size: 12px;
                                        white-space: unset;
                                    }
                                }
                                &.price {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.btn {
    position: relative;

    &[disabled] {
        .loading-icon {
            display: block;
        }
    }

    .loading-icon {
        display: block;
        position: absolute;
        top: 11px;
        right: 10px;
        display: none;
    }
}

.stores-modal {
    .modal-dialog {
        .modal-content {
            border: none;
            border-radius: 0;
            box-shadow: 0px 0px 15px 5px $grey700;
            position: relative;

            .modal-body {
                padding: 0;
            }

            .close {
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 100;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;
                vertical-align: middle;

                &:hover {
                    background-color: $fullWhite;
                }
            }
        }
    }
}

.stores-map {
    background-color: $grey100;

    &__search {
        height: 500px;
        padding: 50px 15px 15px 15px;

        & > i {
            font-size: 70px;
            display: block;
            color: $mainColor;
            text-align: center;
        }

        h2 {
            color: $darkBlack;
            font-size: 28px;
            text-align: center;
            margin-top: 3px;
            margin-bottom: 15px;
        }

        h4 {
            font-size: 16px;
            color: $grey450;
            text-align: center;
            margin-bottom: 25px;
            font-weight: 400;
        }

        .form-group {
            position: relative;

            & > i {
                position: absolute;
                color: $mainColor;
                top: 15px;
                font-size: 18px;
                left: 13px;
            }

            & > .btn {
                position: absolute;
                color: $mainColor;
                top: 3px;
                font-size: 18px;
                right: 3px;
            }

            input {
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 4rem;
                font-size: 24px;
                font-weight: 400;
                line-height: 75px;
                height: 75px;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                &:focus {
                    outline: none;
                    border-color: $mainColor;

                    & + .btn {
                        display: none;
                    }
                }
            }
        }
    }

    &__map {
        position: relative;
        height: 560px;

        &__list {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 320px;
            height: calc(560px - 16px);
            z-index: 10000;
            border: none;
            border-radius: 0 !important;

            .card-header {
                background-color: $mainColor;
                color: $fullWhite;
                border-radius: 0;
                border: none;
            }

            .card-body {
                padding: 0;

                .form-group {
                    padding: 10px;
                    margin-bottom: 0;

                    input {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        padding: 0.375rem 0.75rem;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        border-radius: 0.25rem;

                        &:focus {
                            outline: none;
                            border-color: $mainColor;

                            & + .btn {
                                display: none;
                            }
                        }
                    }
                }

                & > h4 {
                    padding: 0 10px;
                    margin-top: 0;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }

            &__items {
                height: 423px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0 10px;

                a {
                    display: block;
                    transition: 200ms;

                    &:hover {
                        .card {
                            border-color: rgba($grey400, 0.8);
                        }
                    }

                    .card {
                        border-color: $grey100;
                        margin-bottom: 10px;

                        .card-body {
                            display: flex;
                            align-items: center;
                            padding: 10px 7px;

                            .location-icon {
                                width: 65px;
                                text-align: center;
                                margin-right: 7px;

                                i {
                                    font-size: 30px;
                                }

                                span {
                                    display: block;
                                    font-size: 11px;
                                    color: $grey600;
                                }
                            }

                            .store-info {
                                width: calc(100% - 72px);

                                h4 {
                                    font-size: 14px;
                                    margin-bottom: 0px;
                                    color: $mainColor;
                                }

                                p {
                                    width: 100%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-size: 12px;
                                    white-space: nowrap;
                                    color: $grey600;
                                }
                            }
                        }

                        .card-footer {
                            padding: 7px;
                            font-size: 12px;
                            color: $grey700;
                            text-align: center;
                            background-color: transparent;
                            border-color: $grey100;

                            span {
                                display: inline-block;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .btn-back {
                display: block;
                height: 45px;
                line-height: 45px;
                vertical-align: middle;
                padding: 0 10px;
                color: $mainColor !important;

                i {
                    font-size: 24px;
                    margin-right: 13px;
                    position: relative;
                    top: 5px;
                }
            }

            &__detail {
                padding: 10px;
                max-height: 398px;
                overflow-y: scroll;

                p {
                    font-size: 13px;
                    color: $grey600;
                }

                h4 {
                    font-size: 14px;
                }

                ul {
                    li {
                        font-size: 13px;
                        color: $grey500;
                    }
                }

                .card {
                    border-color: $grey100;
                    margin-bottom: 10px;

                    .card-body {
                        display: flex;
                        align-items: center;
                        padding: 10px 7px;

                        .location-icon {
                            width: 65px;
                            text-align: center;
                            margin-right: 7px;

                            i {
                                font-size: 30px;
                            }

                            span {
                                display: block;
                                font-size: 11px;
                                color: $grey600;
                            }
                        }

                        .store-info {
                            width: calc(100% - 72px);

                            h4 {
                                font-size: 14px;
                                margin-bottom: 0px;
                                color: $mainColor;
                            }

                            p {
                                width: 100%;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: 12px;
                                white-space: nowrap;
                                color: $grey600;
                            }
                        }
                    }

                    .card-footer {
                        padding: 7px;
                        font-size: 12px;
                        color: $grey700;
                        text-align: center;
                        background-color: transparent;
                        border-color: $grey100;

                        span {
                            display: inline-block;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}

.pac-container {
    z-index: 10000 !important;
}

.loading-payment-modal {
    .modal-dialog {
        width: 390px;
        max-width: calc(100% - 15px);
    }
    .modal-content {
        border-radius: 11px;
        border: none;
        padding: 20px;

        .modal-body {
            .loading-icon {
                font-size: 48px;
                text-align: center;
                color: $mainColor;
            }

            h3 {
                font-size: 32px;
            }
        }
    }
}

.pm-check {
    padding-left: 0;
    max-width: 500px;
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: 1450px) {
        max-width: 500px;
        width: 100%;
        margin-bottom: 15px;
    }

    .form-check-input {
        display: none;

        &:checked {
            & + .form-check-label {
                border-color: $mainColor;
                &:before {
                    border-width: 5px;
                }
            }
        }
    }

    .form-check-label {
        padding: 10px 10px !important;
        position: relative;
        border: 2px solid rgba($thirdColor, 0.1);
        border-radius: 4px;
        font-weight: normal !important;
        padding-left: 33px !important;
        font-size: 12px !important;

        .benefits-logo {
            display: block;
            width: 173px;
            height: 24px;
            background: url(https://ibkshopstarsasprod.blob.core.windows.net/images/Site/LogonuevoBenefit.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            left: -13px;
        }

        .interbank-logo {
            display: block;
            width: 83px;
            height: 24px;
            background: url(https://ibkshopstarsasprod.blob.core.windows.net/images/Site/dividelo-new.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid $mainColor;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            // content: "";
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $mainColor;
            left: 3px;
            top: 5px;
            opacity: 0;
            transition: 200ms;
        }
    }
}

.stores {
    .card {
        border-radius: 5px;

        .card-header {
            border-bottom: none;
            position: relative;
            background-color: transparent;
            padding-left: 5px;

            .store-info {
                display: block;
                color: $mainColor;

                .store-logo {
                    width: 100px;
                    min-height: 40px;
                    position: relative;
                    margin-right: 15px;

                    img {
                        max-width: 80%;
                        max-height: 95%;
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }

                .form-check {
                    .form-check-input {
                        display: none;
                        &[disabled] {
                            & + .form-check-label {
                                opacity: 0.6;
                            }
                        }
                        &:checked {
                            & + .form-check-label {
                                &:after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .form-check-label {
                        padding-left: 25px;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 17px;
                            height: 17px;
                            border-radius: 50%;
                            border: 1px solid $grey500;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background-color: $mainColor;
                            left: 2px;
                            opacity: 0;
                            transition: 200ms;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                b {
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                }

                small {
                    font-size: 12px;
                    color: $grey700;
                }

                &:hover {
                    text-decoration: none;
                }

                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
                }
            }
        }

        .collapse {
            & > .card-body {
                border: 1px solid $grey400;
                border-radius: 10px;
            }

            .store-products {
                display: flex;
                margin-bottom: 12px;

                .product-img {
                    margin-right: 15px;
                    img {
                        width: 55px;
                    }
                }

                .product-info {
                    font-size: 14px;
                    & > b {
                        color: $mainColor;
                    }

                    .out-stock {
                        color: $danger;
                        font-size: 80%;
                        a {
                            font-weight: bold;
                            text-decoration: underline;
                        }
                    }

                    small {
                        color: $grey500;
                    }

                    b,
                    small {
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.address-locator {
    h2 {
        font-size: 12px;
        margin: 0 0 2px;
        color: #333;
    }

    h3 {
        color: #999;
        font-weight: 400;
        margin: 0;
        line-height: 1.3;
        font-size: 12px;
    }
}

.pickup-store {
    margin-bottom: 20px;

    .card {
        border: 1px solid $mainColor !important;
        border-radius: 5px;
        margin-bottom: 20px;

        .card-body {
            padding-bottom: 45px;
            h4 {
                font-size: 14px;
                margin-bottom: 0px;
                color: $mainColor;
            }

            p {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 12px;
                white-space: nowrap;
                color: $grey600;

                a {
                    text-decoration: underline;
                }
            }

            .btn-edit {
                position: absolute;
                bottom: 15px;
                right: 15px;
                font-size: 12px;
                color: $mainColor !important;
                font-weight: bold;
            }
        }
    }
}

.map-marker-icon {
    background-color: $mainColor;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: block;
    margin: 30px auto;
}

.address-locator-actions {
    .btn {
        min-width: 220px;
        background-image: none;
        background: $mainColor;
        border-radius: 4px;
        height: 39px;
        padding: 10px 0;
        text-shadow: none;
        box-shadow: none;
        border: none;
        font-size: 14px;
        margin: 0;
    }

    a {
        font-size: 13px;
        color: $mainColor;
        text-decoration: underline;
        display: inline-block;
        margin: 18px 0;
    }
}

.address-card {
    padding-bottom: 25px;
    border: 1px solid $mainColor !important;
    margin-bottom: 20px;

    p {
        margin-bottom: 0;
    }

    .btn-edit {
        position: absolute;
        bottom: 15px;
        right: 15px;
        font-size: 12px;
        color: $mainColor !important;
        font-weight: bold;
    }
}

.shipping-days {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    .card-body {
        padding: 7px 15px;
        font-size: 13px;
        i {
            color: $mainColor;
        }
    }
}

.date-picker {
    &.not-valid {
        .form-icon {
            display: none;
        }
    }
    .is-invalid {
        border-color: #dc3545 !important;
        padding-right: 15px;
    }
}

.custom-checkbox {
    font-size: 14px;
    padding-left: 0;
    .custom-control-input {
        &:checked {
            & + .custom-control-label {
                &:before {
                    background-color: $fullWhite !important;
                    border-color: $mainColor;
                }
                &:after {
                    content: "";
                }
            }
        }
        &:focus {
            & + .custom-control-label {
                &:before {
                    box-shadow: none;
                }
            }
        }
    }
    .custom-control-label {
        padding-left: 23px;
        &:before {
            border-radius: 0;
            left: 0;
        }
        &:after {
            content: none;
            background-image: none;
            width: 10px;
            height: 6px;
            border-left: 1px solid $mainColor;
            border-bottom: 1px solid $mainColor;
            transform: rotate(-45deg);
            top: 7px;
            left: 3px;
        }
    }
    a {
        color: inherit;
        text-decoration: underline;
    }
}

#ibkAuthenticationModal {
    .modal-dialog {
        border: none;
        width: 400px;
        max-width: 100%;
        .modal-content {
            border-radius: 50px 0 50px 0;
            border: none;
            .modal-body {
                background-color: #00bb31;
                border-top-left-radius: 50px;
                padding-top: 45px;
                position: relative;
                padding: 45px 25px 25px;
                .close {
                    position: absolute;
                    color: #fff;
                    top: 10px;
                    right: 10px;
                    opacity: 1;
                    span {
                        text-shadow: none;
                        font-weight: 400;
                        opacity: 1;
                    }
                }
                h4 {
                    font-size: 16px;
                    text-align: center;
                    color: #fff;
                    font-weight: 400;
                    margin-bottom: 30px;
                    span {
                        display: block;
                    }
                }
                form {
                    background-color: #fff;
                    border-radius: 50px 0 50px 0;
                    padding: 50px 20px 10px;
                    .form-group {
                        span {
                            border: 1px solid #3d48a0;
                            border-radius: 50%;
                            font-size: 12px;
                            width: 16px;
                            height: 16px;
                            display: block;
                            text-align: center;
                            color: #3d48a0;
                            line-height: 8px;
                            font-weight: 500;
                            position: absolute;
                            bottom: 25px;
                            left: 17px;
                            & + input {
                                padding-left: 25px !important;
                            }
                        }
                        .form-control {
                            border-radius: 0;
                            border-width: 0 0 1px 0;
                            font-size: 14px;
                            padding-left: 0;
                            &:focus {
                                outline: none;
                                box-shadow: none;
                                border-color: #3d48a0;
                            }
                        }
                        .btn-ibk {
                            background: linear-gradient(to right, #00bb31, #3d48a0);
                            border: none;
                            color: #fff;
                            font-size: 14px;
                            border-radius: 2px;
                            width: 150px;
                            margin-top: 5px;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
            .modal-footer {
                height: 50px;
                p {
                    width: 100%;
                    color: #00bb31;
                    font-weight: 500;
                    font-size: 13px;
                    margin: 0;
                }
            }
        }
    }
}

#validateMilesModal {
    .modal-dialog {
        .modal-content {
            border: none;
            .modal-header {
                background-color: #00a94f;
            }
            .modal-body {
                font-size: 20px;
                padding: 50px 55px 35px;
                .btn {
                    display: block;
                    height: 49px;
                    width: 175px;
                    margin-bottom: 20px;
                    padding: 0;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                }
                p {
                    font-size: 20px;
                    small {
                        font-size: 15px;
                        text-align: center;
                        line-height: 20px;
                        display: block;
                        a {
                            color: $mainColor;
                        }
                    }
                }
            }
            .modal-footer {
                border-top: 1px solid rgba($mainColor, 0.5);
                padding: 25px;
                justify-content: center;
            }
        }
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.store-logo {
    position: relative;
    height: 45px;
    img {
        max-width: 100% !important;
        max-height: 45px !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.installments-list {
    &.valid-ibk-card {
        .card {
            border-color: var(--green);
            &.error {
                border-color: var(--red);
                .card-header {
                    .btn {
                        .fas:not(.loading-icon) {
                            color: var(--red);
                        }
                    }
                }
            }
            &-header {
                .btn {
                    .fas:not(.loading-icon) {
                        color: var(--green);
                    }
                }
            }
        }
    }
    .card {
        border: 1px solid #ced4da;
        font-size: 13px;
        &-header {
            background-color: transparent;
            padding: 0;
            border-bottom: none;
            h2 {
                .btn {
                    font-size: 13px;
                    font-weight: normal;
                    color: #242424;
                    height: 34px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .fas:not(.loading-icon) {
                        font-size: 18px;
                        &:before {
                            content: "\f0d8";
                        }
                    }
                    &.collapsed {
                        .fas:not(.loading-icon) {
                            &:before {
                                content: "\f0d7";
                            }
                        }
                    }
                    &:hover {
                        text-decoration: none;
                    }
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
        .list-group {
            border-top: 1px solid #ced4da;
            li {
                display: flex;
                border-color: rgba($grey200, 1);
                padding: 5px;
                &:nth-child(even) {
                    background-color: rgba($grey100, 0.6);
                }
                a {
                    display: flex;
                    width: 100%;
                    color: inherit;
                }
                span {
                    width: 50%;
                    text-align: center;
                }
            }
        }
    }
}
