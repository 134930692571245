.modal-form {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  &::-webkit-scrollbar-thumb {
    border: 4px solid $grey400;
    background-clip: content-box;
  }
}

.form-group {
  .form-control {
    color: #000;
    font-weight: 500 !important;
    &::placeholder {
      color: #B7BABC;
    }
  }
}

.btn {
  font-weight: 500 !important;
}