.card-container {
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 16px;
  max-width: 170px;
  height: 110px;
  width: 100%;
  text-align: center;
  margin: 8px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &.selected {
    border: 2px solid #009FE3;
    padding: 15px;
  }
  
  .tooltip-container {
    position: absolute;
    top: -30px; 
    left: 10px;
    background-color: #e6f5ff;
    color: #000;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 350px;
  }

  &:hover .tooltip-container {
    visibility: visible;
    opacity: 1;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .radio-button {
    position: absolute;
    top: 8px;
    right: 8px;

    input[type='radio'] {
      display: none;
    }

    label {
      width: 20px;
      height: 20px;
      border: 2px solid #009FE3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background: #009FE3;
        border-radius: 50%;
        display: none;
      }
    }

    input[type='radio']:checked+label::after {
      display: block;
    }

  }

  h2 {
    font-size: 14px;
    color: #343434;
    font-weight: 600;
  }
}