.toast {
  position: fixed;
  top: 15px;
  right: 15px;
}

/* Header */

#header {
  margin-bottom: 20px;
}

.bg-orange.-fr {
  height: 11px;
}

.bg-orange {
  background-color: #009fdf;
}

.-tbl {
  width: 100%;
  display: table;
  vertical-align: middle;
}
.-ch {
  padding: 12px 0;
}
.center-wrap {
  max-width: 1529px;
  margin: 0 auto;
}

.-tbl .tb-row {
  display: table-row;
  vertical-align: middle;
}

.-tbl .tb-cell {
  display: table-cell;
  vertical-align: middle;
}
.-c1 {
  width: 40%;
}

#header .logo {
  padding-left: 10px;
  img {
    margin-right: 13px;
  }
}

@media screen and (max-width: 1315px) {
  .logo img {
    width: 140px;
    height: auto;
  }
}

.tb-cell.-c2 {
  width: 95%;
  right: 30px;
  display: block;
  text-align: right;
}
.-tbl .tb-cell {
  display: table-cell;
  vertical-align: middle;
}
.-c2 {
  width: 60%;
  text-align: right;
}
.tb-cell.-c2 {
  width: 95%;
  right: 30px;
  display: block;
  text-align: right;
}
.-c2 {
  width: 60%;
  text-align: right;
}
.tb-cell.-c2 a {
  color: #009fdf;
}
.bg-gris.-fr {
  height: 18px;
}
.bg-gris {
  background-color: #f5f5f5;
}

/* Footer */

#footer {
  background-color: #e8e8e8;
  color: #8f8d8d;
  padding: 5px;
  margin-top: 64px;
  padding: 20px 20px;

  .row {
    margin: 0;
    padding: 0;
  }
}
.center-wrap {
  max-width: 1529px;
  margin: 0 auto;
}
#footer .-w1 {
  width: 50%;
}
#footer .-w1,
#footer .-w2 {
  display: inline-block;
  vertical-align: middle;
}
#footer ul li {
  display: inline-block;
  vertical-align: middle;
}

.payment-providers {
  display: flex !important;
  align-items: center;
}

@media (max-width: 479px) {
  #footer {
    .items {
      display: flex;
      align-items: center;
      li {
        display: flex;
        align-items: center;
        span {
          padding-top: 9px;
        }
        i {
          height: 19px;
          &.mr-2 {
            img {
              max-width: 18px !important;
            } 
          }
          img:not(.kushki-icon) {
            max-width: 30px !important;
          }
        }
      }
    }

    .payment-providers {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
