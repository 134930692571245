@import '../../styles/colors/_colors';
@import '../../styles/variables/_variables';

.cart {
    &__title {
        font-size: 16px;
        color: $mainColor;
        text-transform: uppercase;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        border-bottom: 1px solid $grey300;

        &__icon {
            background-image: url(https://shopstar.pe/arquivos/sprite.png);
            background-position: 48.872611% 22.272727%;
            background-size: 660.71428571429% 540%;
            width: 28px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            font-size: 0;
            margin-top: -7px;
        }
    }

    &__table {
        .table {
            margin-top: 15px;

            thead {
                tr {
                    th {
                        border-top: none;
                        color: $grey450;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }

            tbody {
                .cart-item {

                    &__image {
                        a {
                            display: block;
                            width: 45px;
                            height: 45px;
                            margin: auto;

                            img {
                                object-fit: contain;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }

                    &__info {
                        &__name {
                            color: $darkBlack;
                            font-size: 16px;
                            font-weight: 700;
                            display: block;
                        }

                        &__brand {
                            color: $grey450;
                            font-size: 12px;
                            text-transform: uppercase;
                        }

                        &__offerings {
                            margin-top: 10px;

                            span {
                                color: $darkBlack;
                                font-size: 12px;
                                font-weight: bold;
                                display: block;
                                margin-bottom: 5px;
                            }

                            a {
                                display: block;
                                background-color: $grey200;
                                border-radius: 10px;
                                padding: 7px 83px 7px 18px;
                                font-weight: 400;
                                font-size: 12px;
                                position: relative;

                                &:after {
                                    content: "Agregar";
                                    font-size: 12px;
                                    font-weight: bold;
                                    background-color: $mainColor;
                                    color: $fullWhite;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    display: block;
                                    height: 100%;
                                    border-radius: 10px;
                                    width: 75px;
                                    text-align: center;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }

                    &__price {
                        vertical-align: middle;
                        text-align: center;

                        .d-block {
                            white-space: nowrap;
                        }

                        del {
                            font-size: 14px;
                            color: $grey500;
                            font-weight: bold;
                        }

                        span {
                            font-size: 17px;
                            color: $grey800;
                            font-weight: bold;
                            margin-top: 3px;
                        }
                    }

                    &__quantity {
                        vertical-align: middle;

                        &__picker {
                            display: flex;

                            .btn {
                                width: 30px;
                                height: 30px;
                                display: block;
                                line-height: 30px;
                                text-align: center;
                                border: 1px solid $grey300;
                                vertical-align: middle;
                                padding: 0;
                                border-radius: 0;
                                background-color: $grey100;
                                color: $mainColor;
                                font-weight: bold;
                            }

                            .form-control {
                                border-left: none;
                                border-right: none;
                                border-radius: 0;
                                height: 30px;
                                font-size: 12px;
                                text-align: center;
                                width: calc(100% - 60px);
                                border-color: $grey300;

                                &::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    &__subtotal {
                        color: $grey800;
                        font-size: 17px;
                        font-weight: bold;
                        vertical-align: middle;

                        span {
                            display: block;
                            white-space: nowrap;
                        }
                    }

                    &__actions {
                        vertical-align: middle;

                        .btn {
                            color: $grey450;
                        }
                    }
                }
            }
        }
    }

    &__summary {
        .card {
            border: none;
            background-color: rgba($grey300, .7);

            .card-body {
            }
        }

        &__coupon {
            background-color: $grey100;
            padding: 12px 16px;
            border-radius: 5px;
            margin-bottom: 20px;

            h4 {
                font-size: 14px;
                margin-bottom: 15px
            }

            &__form {
                .form-control {
                    font-size: 13px;
                    height: 38px;
                }

                .btn {
                    font-size: 13px;
                    padding-top: 8px;
                }
            }
        }

        &__details {
            h4 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 15px;
            }

            ul {
                li {
                    margin-bottom: 10px;
                    &:not(.total) {
                        color: rgba($grey600, .8);
                        font-weight: 600;
                    }

                    &.total {
                        color: $darkBlack;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }

        &__continue {
            margin-top: 45px;
            .btn {
                width: 240px;
                margin: auto;
                display: block;
                height: 45px;
            }
        }
    }
}
