
.header {
  .navbar {
    &-brand {
      img {
        height: 30px;
      }
    }

    &-actions {
      .form-inline {
        .btn {
        }

        .btn-outline-main {
          border-color: transparent;
          font-size: 13px;
        }

        .btn-second {
          font-size: 11px;
        }
      }

      .navbar-text {
        color: $mainColor;
        font-size: 13px;

        .dropdown-menu {
          left: auto !important;
          right: 0 !important;
          margin-top: 13px !important;
          padding: 0;
          border-radius: 5px;

          .dropdown-item {
            font-size: 11px;
            color: $secondContentText;
            transition: 200ms;
            cursor: pointer;

            &:hover {
              background-color: $fifthBackground;
            }
          }
        }

        a {
          position: relative;
          cursor: pointer;

          &.dropdown-toggle {
            &:after {
              content: none;
            }
          }

          i {
            position: relative;
            top: 5px;
          }

          img {
            height: 28px;
            margin-left: 10px;
            margin-right: 8px;
          }

          .badge {
            position: absolute;
            background: $thirdColor;
            border-radius: 50%;
            color: $fullWhite;
            font-size: 10px;
            top: -5px;
            right: 7px;
          }
        }
      }
    }

    &-mobile {
      background-color: $mainColor;
      display: none;

      &.logged-in {
        background: $fullWhite;
        border-bottom: 1px solid $grey200;
        box-shadow: 0px 0 4px 0px $grey200;

        .btn-link {
          color: $mainColor;

          &:hover,
          &:focus,
          &:active {
            color: $mainColor;
          }
        }

        .navbar-brand {
          .logo-color {
            display: block;
          }

          .logo-white {
            display: none;
          }
        }
      }

      .navbar-brand {
        margin: 0;

        .logo-color {
          display: none;
        }
      }

      .btn-link {
        color: $fullWhite;
        font-size: 21px;

        &:hover,
        &:focus,
        &:active {
          color: $fullWhite;
        }
      }
    }

    &-menu {
      .close-btn {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;

        &:before, &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $fullWhite;
          top: 50%;
          left: 50%;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      .navbar-brand {
        display: none;

        img {
          height: 24px;
        }
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            font-size: 12px;
            padding-left: 50px;
            padding-right: 50px;
            position: relative;

            &:after {
              content: "";
              display: block;
              width: 1px;
              height: 30px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              background-color: $minWhite;
            }
          }

          &:last-child {
            .nav-link {
              padding-right: 0;

              &:after {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    &.open {
      .sidebar-backdrop {
        display: block;
      }

      .navbar-menu {
        left: 0;
        box-shadow: 0 0 20px 2px $darkBlack;
      }
    }

    .sidebar-backdrop {
      content: "";
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $lightBlack;
      transition: 300ms;
      z-index: 100
    }

    .navbar {

      &-mobile {
        display: block;
      }

      &-desktop {
        display: none;
      }

      &-menu {
        position: fixed;
        top: 0;
        width: 250px;
        height: 100vh;
        z-index: 101;
        transition: 300ms;
        left: -250px;
        padding: 35px;

        .navbar-brand {
          display: block;
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          width: 100%;
          text-align: center;
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              padding: 20px 5px;
              text-align: center;
              border: 1px solid $fullWhite;
              margin-top: -1px;
              border-left: none;
              border-right: none;
              font-size: 15px;

              &:after {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}
