/*
	Site Colors
*/
$mobileBreak: 767px;

$mainColor: #009FE3;
$secondColor: #131313;
$thirdColor: #6b6b6b;
$fourthColor: #7048AB;

$defaultText: #0F191E;

$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$danger: #EB0046;
$warning: #ffc107;
$info: #17a2b8;

$defaultText: #6a6969;
$defaultTitleText: #6a6969;
$defaultContentText: #9e9e9e;
$secondContentText: #595959;
$thirdContentText: #7e7e7e;
$dangerContentText: #e14928;

$defaultBackground: #e0e6e8;
$secondBackground: #f9f9f9;
$thirdBackground: #f0f0f0;
$fourthBackground: #f8f7f7;
$fifthBackground: #e8e7e7;
$sixthBackground: #f0efee;

$headerBackgroundColor: $mainColor;
$headerFontColor: $fullWhite;
/*
	External colors
*/
$facebookColor: #3B5998;
$wappColor: #02c754;
$twitterColor: #01a2fc;
$instagramColorLeft: #773e8e;
$instagramColorMiddle: #b40665;
$instagramColorRight: #e19931;
$googleColor: #DD4B39;
$linkedinColor: #0073B2;
/*

*/
$ionicons-font-path: "node_modules/ionicons/fonts" !default;
$nebular-icons-font-path: "node_modules/nebular-icons/fonts" !default;
$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts" !default;
