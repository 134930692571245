@font-face {
    font-family: Rubik;
    src: url('/fonts/Rubik-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: Rubik;
    src: url('/fonts/Rubik-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: Rubik;
    src: url('/fonts/Rubik-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: Rubik;
    src: url('/fonts/Rubik-Medium.ttf');
    font-weight: 500;
}

body,
html{
    font-family: Rubik !important;
}
