.footer {
  padding: 40px 0;
  color: $fullWhite;

  .row {
    div[class^="col-"] {
      &:last-child {
        .footer__section {
          &:after {
            content: none;
          }
        }
      }

      .footer__section {
        &:after {
          display: block;
          content: "";
          height: 100%;
          position: absolute;
          top: 0;
          right: -15px;
          width: 1px;
          background-color: $fullWhite;
        }
      }
    }
  }

  &__section {
    &__menu {
      li {
        text-align: center;

        a {
          font-size: 12px;
          color: $fullWhite;
          display: block;
          padding: 10px 0;
          font-weight: 500;
        }
      }
    }

    &__contact-info {
      margin-bottom: 25px;

      p {
        margin-bottom: 7px;
        text-align: center;
        font-size: 12px;

        &:first-child {
          font-weight: 500;
        }
      }
    }

    &__options {
      ul {
        display: flex;
        padding: 0 40px;

        li {
          width: 50%;

          a {
            display: block;

            img {
              width: 65%;
              display: block;
              margin: auto;
            }
          }
        }
      }
    }

    &__social-networks {
      p {
        font-weight: 500;
        font-size: 12px;
        text-align: center;

        &.links {
          a {
            width: 35px;
            height: 35px;
            display: inline-block;
            line-height: 35px;
            text-align: center;
            color: $mainColor;
            background-color: $fullWhite;
            border-radius: 50%;
            margin: 7px;
            font-size: 17px;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media(max-width: $mobileBreak) {
    .row {
      div[class^="col-"] {
        &:last-child {
          .footer__section {
            &:after {
              content: none;
            }
          }
        }

        .footer__section {

          &:after {
            content: "";
            display: block;
            width: 50%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $fullWhite;
            top: auto;
          }
        }
      }
    }

    &__section {
      position: relative;

      &__menu {
        padding-bottom: 10px;
        li {
          a {
            font-size: 12px;
            color: $fullWhite;
            display: block;
            padding: 5px 0;
            font-weight: 500;
          }
        }
      }

      &__contact-info {
        margin-bottom: 10px;
      }

      &__options {
        ul {
          flex-direction: column;

          li {
            width: 100%;
            margin-bottom: 10px;

            a {
              img {
                width: 80px;
              }
            }
          }
        }
      }
    }
  }
}
