@import "./../../styles/colors/colors";
@import "./../../styles/variables/variables";

.v3 {
    .header {
        padding: 20px 30px;
        border-bottom: 1px solid $grey300;
        position: relative;
        z-index: 10;
        background-color: #fff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
        img {
            width: 130px;
        }
    }
    .stepper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        margin-bottom: 65px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;

        &__item {
            display: block;
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;

            span {
                width: 41px;
                height: 41px;
                display: block;
                border-radius: 50%;
                background-color: #b7babc;
                z-index: 10;
                text-align: center;
                font-weight: 500;
                font-size: 24px;
                line-height: 41px;
                text-align: center;
                letter-spacing: -0.1px;
                color: #fff;
            }

            img {
                display: none;
                width: 18px;
                margin: auto;
            }

            .name {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                margin-top: 13px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.1px;
                white-space: nowrap;
                text-align: center;
                color: #b7babc;
                font-size: 18px;
                font-weight: 500;
                padding-left: 12px;
                background-image: url(/grey-star.svg);
                background-repeat: no-repeat;
                background-position: 0 50%;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                width: 50%;
                height: 3px;
                background-color: #b7babc;
                position: absolute;
                top: 19px;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }

            &.active {
                span {
                    background-color: $mainColor;
                    color: #fff;
                }
                .name {
                    background-image: url(/purple-star.svg);
                    color: $mainColor;
                }
                &:before {
                    background-color: $mainColor;
                }
            }

            &.completed {
                span {
                    background-color: $mainColor;
                    color: #fff;
                    border-color: $mainColor;
                }

                .name {
                    background-image: url(/purple-star.svg);
                    color: $mainColor;
                }

                img {
                    display: block;
                }

                &:before {
                    background-color: $mainColor;
                }

                &:after {
                    background-color: $mainColor;
                }
            }

            @media (max-width: 479px) {
            }
        }

        @media (max-width: 479px) {
            width: calc(100% - 80px);
            margin-top: 45px;
            margin-bottom: 90px;
            &__item {
                .name {
                    width: 70px;
                    white-space: normal;
                    margin-left: calc((70px - 30px) / -2);
                }
            }
        }
    }
    .summary {
        margin-bottom: 25px;
        font-size: 14px;
        .toggler {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            border-bottom: none;
            padding-bottom: 0;
            display: none;
            &:not(.collapsed) {
                padding-bottom: 24px;
                border-bottom: 1px solid #d9dadb;
                margin-bottom: 20px;
                .total {
                    img {
                        transform: rotate(-180deg);
                    }
                }
            }
            .total {
                margin-left: auto;
                img {
                    margin-left: 5px;
                }
            }
        }
        h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.1px;
            margin-bottom: 33px;
        }
        .card {
            border-radius: 15px;
            border: none;
            background-color: #f7f7f7;
            .card-header {
                border-radius: 20px 20px 0 0;
                background-color: rgba(207, 235, 247, 0.3);
                padding: 35px;
                border: none;
                position: relative;
                &:after {
                    content: "";
                    width: calc(100% - 70px);
                    bottom: 1px;
                    border-top: 1px solid #878c8f;
                    left: 35px;
                    display: block;
                    position: absolute;
                }
                .coupon-form {
                    label {
                        color: #0f191e;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .form-group {
                        display: flex;
                        margin: 0;
                        .form-control {
                            box-sizing: border-box;
                            height: 48px;
                            width: 255px;
                            border: 1px solid #d9dadb;
                            border-radius: 4px;
                            background-color: #ffffff;
                            margin-right: 28px;
                            font-size: 14px;
                        }
                        .btn {
                            width: 130px;
                            color: #ffffff;
                            font-size: 14px;
                            letter-spacing: 0.09px;
                            line-height: 17px;
                            text-align: center;
                            height: 48px;
                            border-radius: 8px;
                        }
                    }
                }
            }
            .card-body {
                padding: 20px;
            }
            .card-footer {
                background-color: rgba(207, 235, 247, 0.3);
                padding: 25px 32px;
                border-radius: 0 0 20px 20px;
                border: none;
                position: relative;
                &:after {
                    content: "";
                    width: calc(100% - 70px);
                    top: 1px;
                    border-top: 1px solid #878c8f;
                    left: 35px;
                    display: block;
                    position: absolute;
                }
                a {
                    color: $mainColor;
                    text-decoration: underline;
                }
            }
        }
        &__title {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.1px;
            color: #0f191e;
            font-weight: 500;
            margin-bottom: 12px;
        }
        &__item {
            display: flex;
            margin-bottom: 18px;
            align-items: center;
            justify-content: space-between;
            b {
                font-weight: 500;
                &.total-ibk {
                    color: #49f98f;
                    img {
                        width: 25px;
                        margin-right: 7px;
                    }
                }
            }
            &.total {
                font-size: 16px;
                font-weight: 500;
                .ibk-total {
                    color: #05be50;
                    svg {
                        margin-right: 15px;
                    }
                }
            }
        }
        &__product {
            display: flex;
            margin-bottom: 12px;
            align-items: flex-start;
            &__info {
                width: calc(100% - 96px);
                .name,
                .quantity,
                .price {
                    color: #0f191e;
                    font-family: Rubik;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 7px;
                }
                .ibk-price {
                    color: #49f98f;
                    font-weight: 500;
                    img {
                        width: 25px;
                        margin-right: 7px;
                    }
                }
                .seller {
                    color: #878c8f;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 7px;
                }
                .services {
                    &__item {
                        border-radius: 20px;
                        background-color: #ffffff;
                        padding: 8px 14px;
                    }
                }
                .unavailable-product-message {
                    margin-top: 8px;
                    background-color: #fbccda;
                    padding: 10px;
                    color: #0f191e;
                    font-size: 10px;
                    letter-spacing: -0.1px;
                    line-height: 10px;
                    border-radius: 8px;
                    a {
                        color: #0f191e;
                        text-decoration: underline;
                        display: none;
                    }
                }
            }
            &__price {
                font-size: 14px;
                color: #878c8f;
                text-decoration: line-through;
                margin-left: auto;
            }
            &__special-price {
                font-size: 14px;
                width: 80px;
                text-align: right;
            }
        }
        &__info {
            display: flex;
            align-items: flex-start;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.001em;
            color: #000000;
            margin-top: 15px;
            svg {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                flex: 20px 0 0;
                margin-top: 1px;
            }
        }
        .separator {
            border: 1px solid #eaeaea;
            margin-bottom: 25px;
        }
        .ibk-discount {
            background: #cdf2dc;
            display: flex;
            justify-content: space-between;
            border-radius: 20px;
            padding: 18px 16px;
            b {
                font-weight: 500;
            }
            & > span {
                &:last-child {
                    padding-left: 12px;
                }
            }
        }

        @media (max-width: 479px) {
            .toggler {
                display: flex;
            }
        }
    }
    .section {
        margin-bottom: 40px;
        background: #ECEDED;
        border: 1px solid #D9DADB;
        border-radius: 20px;
        padding: 30px 20px;
        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -1px;
            color: #0f191e;
            margin-bottom: 10px;
        }
        &__subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.001em;
            color: #000000;
            margin-bottom: 10px;
        }
        &__separator {
            border-top: 1px dashed #B7BABC;
            margin-top: 25px;
            margin-bottom: 25px;
        }
        &__info {
            display: flex;
            // align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.001em;
            color: #000000;
            margin-bottom: 40px;
            svg {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                flex: 20px 0 0 0;
                margin-top: 1px;
            }
        }
        &__form {
            .form-group {
                margin-bottom: 24px;
                &.invalid {
                    .form-control {
                        border-color: $danger;
                    }
                }
                label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 17px;
                    align-items: center;
                    letter-spacing: -0.001em;
                    color: #878c8f;
                    margin-bottom: 4px;
                    svg {
                        margin-left: 7px;
                    }
                }
                .form-control {
                    padding: 10px 12px 10px 20px;
                    gap: 12px;
                    height: 48px;
                    border: 1px solid #d9dadb;
                    border-radius: 4px;
                    background-color: #fff;
                }
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background: transparent;
                    background-image: url("/select-arrow.svg");
                    background-repeat: no-repeat;
                    background-position-x: calc(100% - 20px);
                    background-position-y: 21px;
                    &:before {
                        content: "";
                        display: block;
                    }
                    &::-ms-expand {
                        display: none;
                    }
                }

                &.radio-button {
                    display: flex;
                    flex-wrap: wrap;
                    .form-check {
                        padding-left: 0;
                        padding-right: 1.25rem;
                        margin-bottom: 18px;
                        input {
                            visibility: hidden;
                        }
                        input {
                            &:checked {
                                & + label {
                                    border-color: $mainColor;
                                    color: $mainColor;
                                }
                            }
                            &:focus {
                                & + label {
                                    border-color: $mainColor;
                                    color: $mainColor;
                                }
                            }
                        }
                        label {
                            height: 48px;
                            background: #ffffff;
                            border: 2px solid #d9dadb;
                            border-radius: 24px;
                            width: 135px;
                            color: #878c8f;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }

                .btn-submit {
                    padding: 12px;
                    gap: 10px;
                    width: 288px;
                    max-width: 100%;
                    height: 56px;
                    border-radius: 8px;
                    margin-top: 40px;
                    &[disabled]:not(.collapsed) {
                        background-color: #b7babc !important;
                        border-color: transparent !important;
                        font-size: 0;
                        &:before {
                            content: "\f1ce";
                            font-family: "Font Awesome 5 Free";
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            animation: fa-spin 2s linear infinite;
                            font-weight: 900;
                            font-size: 16px;
                            cursor: not-allowed;
                        }
                    }
                }
            }
            &.credit-card {
                .cvv-help {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .cvv-help__content {
                            display: block;
                        }
                    }
                    &__content {
                        display: none;
                        position: absolute;
                        width: 225px;
                        bottom: 35px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #fff;
                        padding: 12px 16px;
                        border: 1px solid #e3e3e3;
                        color: #0f191e;
                        font-family: Rubik;
                        font-size: 14px;
                        letter-spacing: -0.1px;
                        line-height: 20px;
                        font-weight: normal;
                        box-shadow: 0 2px 4px 2px rgba(51, 51, 51, 0.12);
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 15px solid transparent;
                            border-right: 15px solid transparent;
                            border-top: 15px solid rgba(51, 51, 51, 0.08);
                            position: absolute;
                            bottom: -14px;
                            left: calc(50% + 5px);
                            transform: translateX(-50%);
                        }
                        &:after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 13px solid transparent;
                            border-right: 13px solid transparent;
                            border-top: 14px solid #fff;
                            position: absolute;
                            bottom: -13px;
                            left: calc(50% + 5px);
                            transform: translateX(-50%);
                        }
                    }
                }
                .cc-input {
                    position: relative;
                    .form-control {
                        padding: 3px 12px 10px 12px;
                    }
                    .card-logo {
                        position: absolute;
                        bottom: 18px;
                        left: 15px;
                        display: none;
                    }
                    .ibk-card-ico {
                        position: absolute;
                        font-size: 20px;
                        bottom: 10px;
                        right: 20px;
                    }
                }
            }
            &.email-form {
                width: 400px;
                max-width: 100%;
                padding-top: 80px;
                padding-left: 40px;
                padding-right: 40px;
                margin-left: auto;
                margin-right: auto;
                h5 {
                    font-size: 16px !important;
                    margin-bottom: 25px !important;
                }
            }
        }
        &__address-list {
            &__item {
                cursor: pointer;
                position: relative;
                padding-right: 20px;
                margin-bottom: 15px;
                background-color: #fff;
                border-color: $mainColor;
                padding: 15px;
                border-radius: 10px;
                &.selected {
                    &:after {
                        border-color: $mainColor;
                    }
                    &:before {
                        opacity: 1;
                    }
                }
                &.error {
                    &:after {
                        border-color: $danger;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    transition: 300ms;
                    background-color: $mainColor;
                    opacity: 0;
                    transition: 200ms;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 1px solid #b7babc;
                    transition: 300ms;
                }
                &:not(:last-child) {
                    padding-bottom: 18px;
                    border-bottom: 1px solid #d9dadb;
                    margin-bottom: 18px;
                }
                .address-name {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.001em;
                    color: #0f191e;
                    padding: 0 8px;
                    gap: 10px;
                    width: 94px;
                    height: 24px;
                    background: #94daf8;
                    border-radius: 20px;
                    margin-bottom: 15px;
                }
                p:not(.actions) {
                    margin-bottom: 8px;
                }
                .actions {
                    margin-bottom: 0;
                    display: flex;
                    a {
                        margin-right: 18px;
                        font-weight: 500;
                        text-decoration: underline;
                    }
                }
            }
        }
        &__action-link {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.001em;
            color: $mainColor !important;
            text-decoration: underline !important;
            margin-top: 25px;
            svg {
                margin-right: 5px;
            }
        }
        &__address {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 15px 20px;
            position: relative;
            font-size: 14px;
            .info {
                display: flex;
                align-items: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.001em;
                color: #000000;
                margin-bottom: 15px;
                svg {
                    margin-right: 5px;
                }
            }
            p {
                margin-bottom: 8px;
                padding-right: 60px;
            }
            a {
                font-weight: 500;
                color: $mainColor;
                text-decoration: underline;
                position: absolute;
                top: 50px;
                right: 20px;
            }
        }
        &__stores {
            margin-top: 25px;
            &__item {
                &__title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    color: #0f191e;
                    margin-bottom: 15px;
                }
                &__products {
                    &__item {
                        display: flex;
                        margin-bottom: 15px;
                        &__image {
                            width: 65px;
                            height: 65px;
                            border: 1px solid #d9dadb;
                            border-radius: 5px;
                            margin-right: 8px;
                            img {
                                max-width: 100%;
                                object-fit: contain;
                            }
                        }
                        &__info {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            padding-top: 10px;
                            .name {
                                color: #000;
                                margin-bottom: 5px;
                            }
                            .quantity {
                                color: #878c8f;
                            }
                            .price {
                                display: none;
                            }
                        }
                    }
                }
                &__shipping {
                    margin-top: 25px;
                    &__warning {
                        display: flex;
                        align-items: flex-start;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.001em;
                        color: #000000;
                        background-color: #fff5cc;
                        padding: 16px;
                        border-radius: 12px;
                        margin-top: 25px;
                        svg {
                            margin-right: 7px;
                        }
                    }
                    &__methods {
                        &__item {
                            border: 1px solid #eceded;
                            border-radius: 20px;
                            display: flex;
                            flex-wrap: wrap;
                            padding: 15px 20px;
                            margin-bottom: 15px;
                            cursor: pointer;
                            transition: 200ms;
                            position: relative;
                            .shipping-cost {
                                position: absolute;
                                background: #cfebf7;
                                border-radius: 0px 20px;
                                padding: 12px;
                                font-size: 14px;
                                font-weight: 500;
                                top: 0;
                                right: -1px;
                                text-align: center;
                            }
                            &:hover {
                                border-color: rgba($mainColor, 0.5);
                            }
                            &.selected {
                                border-color: $mainColor;
                                border-width: 2px;
                            }
                            img {
                                width: 60px;
                                height: 60px;
                                object-fit: contain;
                                margin-right: 12px;
                            }
                            &__name {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                letter-spacing: -0.1px;
                                color: #0f191e;
                                margin-top: 10px;
                            }
                            &__description {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: -0.1px;
                                color: #0f191e;
                            }
                            &__picker {
                                width: 100%;
                                margin-top: 20px;
                                .form-group {
                                    margin-bottom: 0;
                                    label {
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 17px;
                                        align-items: center;
                                        letter-spacing: -0.001em;
                                        color: #878c8f;
                                        margin-bottom: 4px;
                                    }
                                    .form-control,
                                    p {
                                        padding: 10px 12px 10px 20px;
                                        gap: 12px;
                                        height: 48px;
                                        border: 1px solid #d9dadb;
                                        border-radius: 4px;
                                    }
                                    .btn {
                                        padding: 12px;
                                        gap: 10px;
                                        width: 215px;
                                        max-width: 100%;
                                        height: 56px;
                                        border-radius: 8px;
                                    }
                                }
                                .date-picker {
                                    position: relative;
                                    input {
                                        border: 1px solid #d9dadb;
                                        box-sizing: border-box;
                                        height: 48px;
                                        border-radius: 4px;
                                        font-size: 16px;
                                        color: #000;
                                        font-weight: 600;
                                    }
                                    .form-icon {
                                        position: absolute;
                                        bottom: 15px;
                                        font-size: 18px;
                                        right: 15px;
                                        margin: 0;
                                    }
                                }
                                &__limit {
                                    display: flex;
                                    align-items: flex-start;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    letter-spacing: -0.001em;
                                    color: #000000;
                                    margin-bottom: 15px;
                                    svg {
                                        margin-right: 7px;
                                    }
                                }
                                .pickup-store {
                                    .store-address-card {
                                        padding: 24px 0;
                                        border-top: 1px solid #d9dadb;
                                        border-bottom: 1px solid #d9dadb;
                                        margin-bottom: 24px;
                                        display: flex;
                                        &__info {
                                            width: calc(100% - 180px);
                                            &__name {
                                                font-weight: 500 !important;
                                            }
                                            p {
                                                font-weight: 400;
                                                font-size: 16px;
                                                line-height: 18px;
                                                color: #0f191e;
                                                margin-bottom: 0;
                                                line-height: 20px;
                                                &:first-child {
                                                    margin-bottom: 7px;
                                                }
                                            }
                                        }
                                        .btn {
                                            padding: 10px;
                                            width: 170px;
                                            height: 56px;
                                            background: #ffffff;
                                            border-width: 2px !important;
                                            border-radius: 8px;
                                            margin-left: 10px;
                                            flex: 170px 0 0;
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                    }
                                }
                                .alternative-pickup-person {
                                    margin-top: 24px;
                                    padding-top: 24px;
                                    padding-bottom: 24px;
                                    border-top: 1px solid #d9dadb;
                                    span {
                                        margin-right: 10px;
                                    }
                                    a {
                                        font-weight: 500;
                                        text-decoration: underline;
                                        margin-right: 10px;
                                        color: $mainColor;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &__payment-methods {
            &__item {
                border: 1px solid #d9dadb;
                border-radius: 20px;
                padding: 25px 20px;
                margin-bottom: 24px;
                cursor: pointer;
                &__info {
                    position: relative;
                    h4 {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 17px;
                        letter-spacing: -0.1px;
                        color: #0f191e;
                        .total-payment {
                            background: #cfebf7;
                            border-radius: 20px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: -0.1px;
                            color: #0f191e;
                            position: absolute;
                            padding: 6px 13px;
                            top: -5px;
                            right: 0px;
                        }
                    }
                    p {
                        width: calc(100% - 170px);
                    }
                    .logo-ibk {
                        height: 16px;
                        margin-right: 8px;
                    }
                    svg {
                        height: 35px;
                        width: auto;
                    }
                    img {
                        height: 35px;
                    }
                    .no-commission {
                        background: #05be50;
                        border-radius: 20px;
                        padding: 6px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.001em;
                        color: #ffffff;
                    }

                    @media (max-width: 479px) {
                        h4 {
                            margin-bottom: 20px;
                            span:first-child {
                                display: flex;
                                margin-bottom: 16px;
                            }
                            .total-payment {
                                position: initial;
                            }
                        }
                        p {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &__billing-address {
            background: #f7f8f8;
            border-radius: 20px;
            padding: 25px 30px;
            &__info {
                background: #cfebf7;
                border-radius: 12px;
                display: flex;
                align-items: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.001em;
                color: #000000;
                margin-bottom: 25px;
                padding: 16px;
                svg {
                    margin-right: 5px;
                }
            }
            .form-group {
                &:last-child {
                    margin-bottom: 0;
                }
                .form-control {
                    background-color: #fff;
                }
            }
        }
    }
    .saved-info {
        background: rgba(207, 235, 247, 0.3);
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 24px;
        h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            color: #0f191e;
            margin-bottom: 15px;
        }
        p {
            display: flex;
            margin-bottom: 10px;
            span {
                & + span {
                    margin-left: 5px;
                }
            }
            a {
                color: $mainColor;
                text-decoration: underline;
                font-weight: 500;
            }
            &.info {
                align-items: center;
                display: flex;
                margin-bottom: 0;
                span {
                    margin-left: 5px;
                }
            }
        }
        &__products {
            margin-bottom: 15px;
            &__item {
                display: flex;
                &__image {
                    width: 65px;
                    height: 65px;
                    border: 1px solid #d9dadb;
                    border-radius: 5px;
                    margin-right: 8px;
                    img {
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
                &__info {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    padding-top: 10px;
                    .name {
                        color: #000;
                        margin-bottom: 5px;
                    }
                    .quantity {
                        color: #878c8f;
                    }
                    .price {
                        display: none;
                    }
                }
            }
        }
    }
}

//Modal
.pickup-points-modal-2 {
    .modal-dialog {
        .modal-content {
            .modal-header {
                font-weight: 500;
                font-size: 28px;
                line-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: -1px;
                color: #0f191e;
                padding-top: 24px;
                border: none;
                position: relative;
                a {
                    position: absolute;
                    top: 10px;
                    right: 13px;
                }
            }
            .modal-body {
                padding: 10px 50px 50px;
                .card {
                    .list-group {
                        &:last-child {
                            max-height: 400px;
                            overflow-x: hidden;
                            overflow-y: scroll;
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
                            &::-webkit-scrollbar-track {
                                background: transparent;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #878c8f;
                                border-radius: 100px;
                            }
                            &::-webkit-scrollbar-thumb:hover {
                                background: #878c8f;
                            }
                        }
                        &-item {
                            &:nth-child(even) {
                                background-color: #f7f8f8;
                            }
                            h4 {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                letter-spacing: 0.001em;
                                color: #0f191e;
                                margin-bottom: 0;
                            }
                        }
                        .store-info {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &__detail {
                                width: calc(100% - 115px);
                                p {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: #0f191e;
                                }
                            }
                            .btn {
                                padding: 10px;
                                width: 105px;
                                height: 40px;
                                background: #ffffff;
                                border-width: 2px !important;
                                border-radius: 8px;
                                margin-left: 10px;
                                flex: 104px 0 0;
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                .info {
                    display: flex;
                    align-items: flex-start;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.001em;
                    color: #000000;
                    background-color: #cfebf7;
                    padding: 16px;
                    border-radius: 12px;
                    margin-top: 25px;
                    svg {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

//Components
.custom-control {
    margin-bottom: 12px;
    &-label {
        color: #0f191e;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 18px;
    }
    &.custom-radio {
        .custom-control-input {
            &:checked {
                & + .custom-control-label {
                    &:before {
                        border-color: $mainColor;
                    }
                    &:after {
                        content: "";
                        background-color: $mainColor !important;
                        background-image: none;
                    }
                }
            }
            &:focus {
                & + .custom-control-label {
                    &:before {
                        box-shadow: none;
                    }
                }
            }
        }
        .custom-control-label {
            &:before {
                border-radius: 50%;
                border-width: 2px;
                border-color: #b7babc;
                background-color: transparent;
                width: 18px;
                height: 18px;
                top: 0;
            }
            &:after {
                content: none;
                background-image: none;
                width: 10px;
                height: 10px;
                background-color: transparent;
                top: 4px;
                left: -20px;
                border-radius: 50%;
            }
        }
    }
    &.custom-checkbox {
        .custom-control-input {
            &:checked {
                & + .custom-control-label {
                    &:before {
                        background-color: $mainColor !important;
                        border-color: $mainColor;
                    }
                    &:after {
                        content: "";
                        border-color: #fff;
                        background-image: none;
                    }
                }
            }
            &:focus {
                & + .custom-control-label {
                    &:before {
                        box-shadow: none;
                    }
                }
            }
        }
        .custom-control-label {
            &:before {
                border-radius: 2px;
                left: 0;
                top: 0;
                border-width: 2px;
                border-color: #b7babc;
                background-color: transparent;
            }
            &:after {
                content: none;
                background-image: none;
                width: 10px;
                height: 6px;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(-45deg);
                top: 4px;
                left: 3px;
            }
        }
        &.invalid {
            .custom-control-label {
                color: $danger;
                &:before {
                    border-color: $danger;
                }
            }
        }
    }
}

//Miles
.miles-total {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: #000000;
    background: #cdf2dc;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 25px;
    svg {
        margin-right: 7px;
    }
}
.miles {
    width: 100%;
    background: #f7f8f8;
    border-radius: 20px;
    padding: 25px 32px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 13px;
        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }
    &__exchange {
        width: 100%;
        display: flex;
        justify-content: space-between;
        b {
            font-weight: 500 !important;
        }
        span {
            &:last-child {
                white-space: nowrap;
                text-align: right;
            }
        }
    }
    &__total {
        width: 100%;
        display: flex;
        justify-content: space-between;
        b {
            font-weight: 500 !important;
        }
        span {
            &:last-child {
                white-space: nowrap;
                text-align: right;
            }
        }
    }

    .separator {
        border: 1px solid #b7babc;
        margin: 20px 0;
    }

    .form-group {
        max-width: 300px;
        margin: auto;
        h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            margin-bottom: 16px;
        }
        .input-group {
            .btn {
                height: 48px;
                width: 48px;
                min-width: initial !important;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $mainColor;
                border-color: #eaeaea;
                border-radius: 4px;
                font-size: 28px;
                background-color: #fff;
            }
            .form-control {
                border-radius: 0;
                text-align: center;
                height: 48px;
                border-color: #eaeaea;
                border-left: none;
                border-right: none;
                border-width: 1px;
            }
            &-prepend {
                .btn {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &-append {
                .btn {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}

//Divide it
.divide-it {
    margin-bottom: 20px;
    background: #f7f8f8;
    border-radius: 20px;
    padding: 12px 16px;
    .installments-list {
        margin-bottom: 20px;
        label {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.1px;
            color: #878c8f;
            margin-bottom: 8px;
            svg {
                height: 14px;
                margin-left: 8px;
            }
        }
        .card {
            box-shadow: none;
            border-radius: 4px !important;
            border: 1px solid #d9dadb;
            width: 280px;
            max-width: 100%;

            .list-group {
                border: none !important;
            }
            .btn {
                box-sizing: border-box;
                height: 57px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: -0.1px;
                color: #000000;
                background: url("/select-arrow.svg");
                background-repeat: no-repeat;
                background-position-x: calc(100% - 20px);
                background-position-y: 25px;
            }
            .collapse,
            .collapsing {
                border-top: 1px solid #eaeaea;
                margin-top: -2px;
                ul {
                    li {
                        background-color: #fff;
                        height: 46px;
                        transition: 200ms;
                        &:hover {
                            background-color: rgba(#d9dadb, 0.4);
                        }
                        a {
                            align-items: center;
                            span {
                                width: 100%;
                                padding-left: 12px !important;
                                text-align: left !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Validations
.invalid {
    border-color: $danger !important;
}

input,
select {
    &:focus {
        border-color: $mainColor !important;
    }
}

//Mobile {
.hidden-mobile {
    @media (max-width: 479px) {
        display: none;
    }
}

.visible-mobile {
    display: none;

    @media (max-width: 479px) {
        display: block;
    }
}
