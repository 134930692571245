.react-datepicker-wrapper {
    display: block !important;

    .react-datepicker__input-container {
        display: block !important;

        input {
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            font-size: 13px;
        }
    }
}

.react-datepicker-popper {
    margin: 0 !important;
    z-index: 10000 !important;

    .react-datepicker {
        box-shadow: 0 6px 18px 1px $faintBlack;
        border-radius: 0;
        border: none;

        &__triangle {
            display: none !important;
        }

        &__header {
            background-color: transparent !important;
            border: none !important;
            border-radius: 0 !important
        }

        &__current-month {
            text-transform: uppercase;
            color: $darkBlack !important;
            font-weight: normal !important;
        }

        &__day-names {
            text-transform: uppercase;
            border-top: 1px solid $grey300;
            margin-top: 5px;
            padding-top: 4px;
        }

        &__month {
        }

        &__week {
        }

        &__day {
            margin: 1px !important;
            border-radius: 50% !important;
            width: 1.866rem;
            line-height: 1.866rem;
            font-weight: bold;

            &:hover {
                background-color: #CFEBF7;
            }

            &.react-datepicker__day--keyboard-selected, &.react-datepicker__day--selected {
                background-color: $mainColor;
            }

            &.react-datepicker__day--disabled {
                opacity: .5;
                color: $grey600;
                text-decoration: line-through;
                font-weight: normal;
            }
        }
    }
}
