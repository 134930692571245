@import "../../styles/colors/_colors";
@import "../../styles/variables/_variables";

.header-logo {
  padding: 13px 23px;
  border-bottom: 1px solid #e0e0e0;
  img {
    width: 118px;
  }
  span {
    color: #999;
  }
  @media (max-width: 479px) {
    text-align: center;
  }
}
.checkout-success {
  background-color: #E8F8FF ;
  color: #0F191E;
  &__head {
    text-align: center;
    line-height: 16px;
    margin-bottom: 40px;
    padding-top: 35px;
    img {
      margin-bottom: 15px;
    }
    h2 {
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 36px;
      font-weight: 500;
      margin-bottom: 12px;
      small {
        font-size: 18px;
        display: block;
        line-height: 24px;
        margin-top: 5px;
      }
    }
    p {
      margin-top: 20px;
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 24px;
      b {
        color: white;
        background-color: $mainColor;
        margin-left: 14px;
        padding: 10px 20px;
      }
    }
  }
  &__nav-status {
    background-color: #FFF;
    padding: 20px -20px;
    position: relative;
  }
  &__body {
    &__group {
      padding: 35px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      &:first-child {
        margin-bottom: 35px;
      }
      h3 {
        font-size: 28px;
        letter-spacing: -1px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-left: 36px;
      }
      &__data {
        letter-spacing: 0;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
        b,
        span {
          // display: inherit;
          margin-bottom: 4px;
          margin-right: 10px;
        }
        b {
          font-weight: 500;
        }
      }
      &__divider {
        border-top: 1px solid transparent;
        box-sizing: border-box;
        height: 1px;
        margin: 20px 0;
      }
      &__timer {
        display: flex;
        width: 210px;
        border: 1px solid #FAB600;
        color: #FAB600;
        background-color: #FFF5CC;
        padding: 10px;
        border-radius: 4px;
        img, svg {
          height: 30px;
          color: #FAB600;
          margin-right: 15px;
          margin-left: 10px;
        }
        div {
          display: inline-block;
          margin-top: 3px;
        }
      }
      &__timer_expired {
        width: 340px;
        border: 1px solid #EB0046;
        color: #EB0046;
        background-color: #FBCCDA;
        font-weight: 600;
        img, svg {
          height: 30px;
          color: #EB0046!important;
        }
      }
      &__qrcontact {
        border: 1px dashed #49BFF1;
        background-color: rgba(207, 235, 247, 0.3);
        padding: 15px 10%;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }
      &__qrcontact {
        .iconContactQR {
          width: 80px;
          text-align: right;
          display: inline-block;
        }
        .txtLabelContactQR {
          width: 180px;
          color: #149FE3!important;
          a {
            color: #149FE3!important;
          }
        }
        svg {
          margin-right: 20px;
        }
      }
    }
    &__store {
      padding: 0px;
      // background-color: #E4E4E4;
      background-color: #FFF;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      // border-radius: 20px;
      &__head {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding:0 35px;
        h5 {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          width: 100%;
        }
        h4 {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.1px;
          line-height: 26px;
          margin-bottom: 15px;
        }
        .shipping-type {
          text-transform: inherit;
          line-height: 26px;
          font-size: 14px;
          letter-spacing: 0px;
          border-radius: 20px;
          width: calc(40%);
          text-align: center;
          font-weight: bold;
          color: $fullWhite;
          display: block;
          margin-bottom: 15px;

          &.pickup {
            // background-color: $fourthColor;
            background-color: $mainColor;
          }
          &.delivery {
            background-color: $mainColor;
          }
          &.cancelled {
            background-color: #F5F5F5;
            color: #CBCBCB;
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }
      }
      &__head2 {
        padding: 0 35px;
      }
      &__info {
        //width: calc(65% - 30px);
        //margin-right: 30px;
        padding:35px;
        width: 100%;
        margin-right: 0px;
        background-color: #EFF3F6;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        &__products {
          display: flex;
          width: 100%;
          background-color: white;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          padding:25px 35px;
          margin-bottom: 25px;
          //flex-direction: column;
          &__item {
            margin-bottom: 0px;
            display: flex;
            &__image {
              margin-right: 15px;
              img {
                width: 150px;
              }
            }
            &__details {
              font-size: 16px;
              .name {
                letter-spacing: -0.1px;
                line-height: 24px;
              }
              .seller {
                color: #878c8f;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
              }
              .price {
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                .base-price {
                  text-decoration: line-through;
                  margin-right: 7px;
                  letter-spacing: 0;
                  line-height: 19px;
                }
                .special-price {
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 19px;
                }
              }
              .quantity {
                letter-spacing: -0.1px;
                line-height: 24px;
              }
            }
          }
        }
        &__products:last-child {
          margin-bottom: 0px;
        } 
      }
      &__shipping {
        width: calc(35%);
        background-color: #FFF;

        &__data {
          letter-spacing: 0;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 12px;
          margin-bottom: 23px;
          b,
          span {
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      &__help-text {
        width: 100%;
        color: #0f191e;
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        display: flex;
        align-items: flex-start;
        background-color: #cfebf7;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 15px;
        margin-top: 25px;
        margin-left: 35px;
        margin-right: 35px;
        img {
          color: $mainColor;
          margin-right: 10px;
          margin-top: 4px;
        }
      }
    }
    &__summary {
      border: 0px solid #eceded;
      border-radius: 4px;
      // padding: 32px;
      padding: 0px;
      box-shadow: 0px ​0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin-bottom: 30px;
      h3 {
        display: block;
        font-size: 28px;
        letter-spacing: 0!important;
        line-height: 19px;
        font-weight: 500;
        margin-bottom: 15px;
        padding: 35px 35px 0 35px;
      }
      h4 {
        display: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      &__item {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        // .card-type {
        //   // color: #878c8f;
        // }
        span,
        b {
          letter-spacing: 0;
          line-height: 16px;
        }
        b {
        }
        span:not(.value) {
          color: #0F191E;
        }
        .value {
          color: #0F191E;
          del {
            color: #878c8f;
          }
        }
        .value.ibkPrice {
          color: #05BE50;
          font-weight: bold;
          text-align: right;
          span {
            color: #05BE50;
          }
        }
      }
      &__divider {
        box-sizing: border-box;
        border: 1px solid transparent;
        margin: 20px 0;
      }
      &__info {
        width: 100%;
        color: #0f191e;
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 24px;
        border-radius: 4px;
        background-color: #cfebf7;
        padding: 15px;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        img {
          margin-right: 10px;
          margin-top: 5px;
        }
      }
      &__btn {
        margin-top: 30px;
        .btn {
          height: 48px;
          line-height: 46px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.09px;
          padding: 0;
          width: 100%;
          border-radius: 8px;
        }
      }
      
    }
  }

  @media (max-width: 479px) {
    &__head {
      text-align: center;
      h2 {
        font-size: 28px;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
        small {
          font-size: 14px;
        }
      }
      p {
        padding-left: 10%;
        padding-right: 10%;
        span {
          display: block;
          margin: 0;
        }
        b {
          display: inline-block;
          margin-top: 10px;
          margin-left: 0;
        }
        br {
          display: none;
        }
      }
    }
    &__body {
      &__group {
        padding: 25px;
        &:first-child {
          margin-bottom: 50px;
        }
        h3 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 20px;
        }
        &__data {
          font-size: 15px;
          margin-bottom: 15px;
        }
      }
      &__store {
        padding: 0px;
        &__head {
          flex-direction: column;
          order: 1;
          padding-left: 25px;
          padding-right: 25px;
          h5 {
            font-size: 10px;
          }
          h4 {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
          }
          .shipping-type {
            width: 150px;
            font-size: 10px;
          }
        }
        &__head2 {
          padding-left: 25px;
          padding-right: 25px;
        }
        &__info {
          order: 4;
          width: 100%;
          padding: 25px;
          &__products {
            padding-left: 25px;
            padding-right: 25px;
            &__item {
              &__image {
                img {
                  width: 100px;
                }
              }
              &__details {
                font-size: 14px;
              }
            }
          }
        }
        &__shipping {
          order: 2;
          width: 100%;
          &__data {
            font-size: 14px;
          }
        }
        &__help-text {
          order: 3;
          font-size: 14px;
          display: flex;

          i {
            margin-top: 6px;
          }
        }
      }
      &__summary {
        border: none;
        box-shadow: none;
        padding: 0px;
        margin-top: 20px;
        font-size: 14px;
        h3 {
          display: block;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.1px;
          line-height: 20px;
          margin-bottom: 12px;
          padding: 25px 25px 0 25px;
        }
        h4 {
          font-size: 14px;
        }
        &__item {
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 479px) {
  .header-logo {
    font-size: 13px;
    line-height: 23px;
  }
}
.checkout-success {
  &__order {
    border: none;
    box-shadow: none;
    border-radius: 0;
    .card-header {
      border-radius: 0;
      border: 1px solid $grey200;
      box-shadow: 0px 0px 13px 1px $grey200;
      background-color: transparent;
      padding: 20px 35px;
      h3 {
        font-size: 16px;
        margin: 0;
      }
    }

    .card-body {
      padding: 15px 35px;
    }

    h4 {
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 15px;
    }
    &__detail {
      font-size: 14px;
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style: none;
        color: $grey700;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        b {
          display: inline-block;
          margin-right: 7px;
          color: #868686;
          font-weight: 500;
        }
        span {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  &__summary {
    .card {
      border: 1px solid $grey200;
      box-shadow: 0px 0px 13px 1px $grey200;
      border-radius: 0;
      .card-body {
        ul {
          padding-left: 0;
          margin-bottom: 0;
          li {
            list-style: none;
            font-size: 14px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            b,
            span {
              color: #666666;
              font-weight: 500;
            }
            strong {
              color: #000;
              font-weight: 500;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__list {
    font-size: 14px;
    margin-bottom: 25px;
    .price,
    .quantity,
    .total {
      width: 15%;
      text-align: center;
    }
    thead {
      tr {
        th {
          border: none;
          font-weight: bold;
          color: #434343;
          &:first-child {
            padding-left: 25px;
          }
          &:last-child {
            padding-right: 25px;
          }
          &.product {
            padding-left: 0;
            font-size: 16px;
            text-decoration: underline;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          border: none;
          color: #666666;
          &:first-child {
            display: flex;
            align-items: center;
            padding-left: 25px;
            img {
              margin-right: 8px;
            }
            small {
              display: block;
            }
          }
          &:last-child {
            padding-right: 25px;
          }
          &.product {
            display: flex;
            padding-left: 0;
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
            }
            b {
              color: #000;
            }
          }
        }
      }
    }
  }
}

.keep-buying {
  .btn {
    font-size: 14px;
    text-transform: uppercase;
    height: 45px;
    padding: 0;
    line-height: 41px;
    width: 190px;
    border-width: 2px;
    font-weight: 500;
    margin-left: auto;
    display: block;
  }
}
.checkout-margin-auto {
  margin: 0 auto!important;
}
.checkout-success__timeline {
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative;
  div {
    width: 25%;
    position: relative;
  }
  div::before {
    display: block;
    height: 2px;
    width: 50%;
    background-color: #D9DADB;
    position: absolute;
    content: " ";
    top: 20px;
    z-index: 1;
    left:0%;
  }
  div::after {
    display: block;
    height: 2px;
    width: 50%;
    background-color: #D9DADB;
    position: absolute;
    content: " ";
    top: 20px;
    z-index: 1;
    right:0%;
  }
  div.first-time::before {
    background-color: red;
    visibility: hidden;
  }
  div.last-time::after {
    background-color: blue;
    visibility: hidden;
    
  }
  div {
    p {
      display: block;
      z-index: 99;
      position: relative;
      img {
        width: 40px;
      }
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: #D9D9D9;
      }
    }
  }
}
.checkout-success__section_bg {
  position: relative;
  background-color: #EFF3F6;
  border-left: 10px dotted white;
  border-right: 10px dotted white;
  padding-top: 40px!important;
  padding-bottom: 30px!important;
  margin-bottom: 30px;
  &::before {
    content: " ";
    height: 1px;
    background-color: #EFF3F6;
    position: absolute;
    top: 10px;
    border-top: 7px dotted white;
    left: 0;
    width: 100%;
  }
  &::after {
    content: " ";
    height: 10px;
    background-color: #EFF3F6;
    position: absolute;
    bottom: 5px;
    border-top: 7px dotted white;
    left: 0;
    width: 100%;
  }
  h3 {
    font-size: 18px;
    font-weight: bolder;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-left: 0;
  }
}
.withoutSpacing {
  margin: 0!important;
}
.checkout-success__body__store__help-text {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0px;
  padding: 20px;
  margin-bottom: 25px!important;
}
.checkout-success__body__store .checkout-success__body__store__help-text{
  margin-left: 25px;
  margin-right: 25px;
}
#footer {
  margin-top: 0px;
}
.text-strike {
  text-decoration: line-through;
}