@import "../../styles/colors/_colors";
@import "../../styles/variables/_variables";

$inputBorderColor: #eaeaea;

.v2 {
    .checkout {
        @media (min-width: 1500px) {
            .container {
                max-width: 1184px;
            }
        }
        &.payment {
            .payment-summary {
                margin-bottom: 20px;
                .checkout__body__summary {
                    display: none;
                }
            }
            @media (max-width: 479px) {
                .lateral-summary {
                    .checkout__body__summary {
                        display: none;
                    }
                }
                .payment-summary {
                    .checkout__body__summary {
                        display: block;
                    }
                }
            }
        }
        &__header {
            padding: 13px 23px;
            border-bottom: 1px solid $grey300;
            position: relative;
            z-index: 10;
            background-color: #fff;
            img {
                width: 118px;
            }
            span {
                color: #999;
            }
        }
        &__prompt {
            background-color: #eceded;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            padding: 10px;
        }
        &__navigation {
            padding: 24px 0;
            a {
                font-size: 16px;
                color: $mainColor;
                font-weight: 500;
                display: flex;
                text-decoration: underline;
                align-items: center;
                span {
                    margin-left: 8px;
                }
            }
        }
        &__body {
            &__info-text {
                margin-top: 10px;
                margin-bottom: 25px;
                h5 {
                    color: #0f191e;
                    font-size: 28px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 11px;
                }
                p {
                    display: none;
                }
                @media (max-width: 479px) {
                    p {
                        display: block;
                    }
                }
            }
            &__email-form {
                display: flex;
                justify-content: center;
                &.text-white {
                    .checkout__body__form {
                        h2,
                        h5,
                        label {
                            color: #ffffff !important;
                            text-shadow: 1px 1px 4px #000;
                        }
                    }
                }
                .promo-background {
                    content: "";
                    display: block;
                    position: fixed;
                    top: 53px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: top;
                    background-repeat: no-repeat;
                    transition: 200ms;
                    z-index: 10;
                }
                form {
                    width: 345px;
                    max-width: 100%;
                    padding: 100px 15px;
                    position: relative;
                    z-index: 10;
                    @media (max-width: 479px) {
                        padding: 40px 15px;
                    }
                }
                h2 {
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 32px;
                    margin-bottom: 30px;
                }
                h5 {
                    font-size: 16px;
                    letter-spacing: -0.1px;
                    line-height: 24px;
                    font-weight: normal;
                    margin-bottom: 45px;
                }
            }
            &__form {
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid #eceded;
                &__title {
                    display: flex;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    align-items: center;
                    margin-bottom: 20px;
                    // margin-top: 60px;
                    .number {
                        width: 26px;
                        height: 26px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        letter-spacing: -0.1px;
                        margin-right: 13px;
                        background-color: #eceded;
                        color: #878c8f;
                        &.purple {
                            color: $fullWhite;
                            background: #7048ab;
                        }
                    }
                }
                &__subtitle {
                    color: #0f191e;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 25px;
                    font-weight: normal;
                }
                &__summary {
                    .btn-edit {
                        border-radius: 8px;
                        border-width: 2px;
                        height: 28px;
                        width: 90px;
                        padding: 0;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 12px;
                    }
                    &__item {
                        font-size: 16px;
                        margin-bottom: 24px;
                        b,
                        span {
                            line-height: 24px;
                            display: block;
                        }
                    }
                }
                &.stores-list {
                    border-bottom: 0;
                    .card {
                        background-color: #f7f8f8;
                        border: none;
                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }
                }
                .card {
                    // box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.07);
                    border-radius: 21px;
                    border: 1px solid #eceded;

                    &.step-form {
                        box-shadow: none !important;
                        border: none !important;
                        .card-body {
                            padding: 0;
                        }
                    }
                    .card-footer {
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        h4 {
                            color: #0f191e;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 18px;
                            margin-bottom: 40px;
                        }
                        .btn {
                            height: 55px;
                            border-radius: 8px;
                            padding: 18px 25px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                            font-size: 14px;
                            letter-spacing: 0.09px;
                            line-height: 17px;
                            text-align: center;
                            min-width: 290px;
                            max-width: 100%;
                        }

                        @media (max-width: 479px) {
                            h4 {
                                text-align: center;
                            }
                            .btn {
                                width: 100%;
                            }
                        }
                    }
                }
                .form-group {
                    margin-bottom: 24px;
                    position: relative;
                    label {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.1px;
                        color: #878c8f;
                        .cvv-help {
                            position: relative;
                            cursor: pointer;
                            &:hover {
                                .cvv-help__content {
                                    display: block;
                                }
                            }
                            &__content {
                                display: none;
                                position: absolute;
                                width: 225px;
                                bottom: 35px;
                                left: 50%;
                                transform: translateX(-50%);
                                background-color: #fff;
                                padding: 12px 16px;
                                border: 1px solid #e3e3e3;
                                color: #0f191e;
                                font-family: Rubik;
                                font-size: 14px;
                                letter-spacing: -0.1px;
                                line-height: 20px;
                                font-weight: normal;
                                box-shadow: 0 2px 4px 2px rgba(51, 51, 51, 0.12);
                                &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 15px solid transparent;
                                    border-right: 15px solid transparent;
                                    border-top: 15px solid rgba(51, 51, 51, 0.08);
                                    position: absolute;
                                    bottom: -14px;
                                    left: calc(50% + 5px);
                                    transform: translateX(-50%);
                                }
                                &:after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 13px solid transparent;
                                    border-right: 13px solid transparent;
                                    border-top: 14px solid #fff;
                                    position: absolute;
                                    bottom: -13px;
                                    left: calc(50% + 5px);
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }
                    .sub-label {
                        display: block;
                        margin-top: -6px;
                        font-size: 14px;
                        color: #000;
                    }
                    .form-control {
                        border: 2px solid $inputBorderColor;
                        box-sizing: border-box;
                        height: 57px;
                        border-radius: 8px;
                        font-size: 16px;

                        &.placeholder {
                            color: #878c8f;
                        }

                        &:focus {
                            border-color: $mainColor;
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    .custom-control {
                        &-label {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: -0.5px;
                            color: #0f191e;
                        }
                    }

                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        text-indent: 1px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background: transparent;
                        background: url("/select-arrow.svg");
                        background-repeat: no-repeat;
                        background-position-x: calc(100% - 20px);
                        background-position-y: 25px;
                        &:before {
                            content: "";
                            display: block;
                        }
                        &::-ms-expand {
                            display: none;
                        }
                    }

                    .btn {
                        height: 55px;
                        border-radius: 8px;
                        padding: 18px 25px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        font-size: 14px;
                        letter-spacing: 0.09px;
                        line-height: 17px;
                        text-align: center;
                        min-width: 290px;
                        max-width: 100%;
                        @media (max-width: 479px) {
                            width: 100%;
                        }
                    }
                    p {
                        b {
                            font-weight: 500 !important;
                        }
                    }
                }
                .custom-control {
                    margin-bottom: 12px;
                    &-label {
                        color: #0f191e;
                        font-size: 16px;
                        letter-spacing: -0.1px;
                        line-height: 18px;
                    }
                    &.custom-radio {
                        .custom-control-input {
                            &:checked {
                                & + .custom-control-label {
                                    &:before {
                                        border-color: $mainColor;
                                    }
                                    &:after {
                                        content: "";
                                        background-color: $mainColor !important;
                                    }
                                }
                            }
                            &:focus {
                                & + .custom-control-label {
                                    &:before {
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                        .custom-control-label {
                            &:before {
                                border-radius: 50%;
                                border-width: 2px;
                                border-color: #b7babc;
                                background-color: transparent;
                                width: 18px;
                                height: 18px;
                                top: 0;
                            }
                            &:after {
                                content: none;
                                background-image: none;
                                width: 10px;
                                height: 10px;
                                background-color: transparent;
                                top: 4px;
                                left: -20px;
                                border-radius: 50%;
                            }
                        }
                    }
                    &.custom-checkbox {
                        .custom-control-input {
                            &:checked {
                                & + .custom-control-label {
                                    &:before {
                                        background-color: $mainColor !important;
                                        border-color: $mainColor;
                                    }
                                    &:after {
                                        content: "";
                                        border-color: #fff;
                                    }
                                }
                            }
                            &:focus {
                                & + .custom-control-label {
                                    &:before {
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                        .custom-control-label {
                            &:before {
                                border-radius: 2px;
                                left: 0;
                                top: 0;
                                border-width: 2px;
                                border-color: #b7babc;
                                background-color: transparent;
                            }
                            &:after {
                                content: none;
                                background-image: none;
                                width: 10px;
                                height: 6px;
                                border-left: 2px solid #fff;
                                border-bottom: 2px solid #fff;
                                transform: rotate(-45deg);
                                top: 4px;
                                left: 3px;
                            }
                        }
                    }
                }
                .help-text {
                    font-size: 16px;
                    line-height: 24px;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.5px;
                    color: #b7babc;
                }
                .ibk-discount {
                    background: #cdf2dc;
                    border-radius: 20px;
                    padding: 18px 16px;
                    font-size: 14px;
                    svg {
                        margin: 0 5px;
                        position: relative;
                        top: -2px;
                    }
                }
                .additional-address {
                    margin-bottom: 10px;
                    padding-left: 40px;
                    position: relative;
                    color: #000;
                    margin-bottom: 20px;
                    cursor: pointer;
                    &.error {
                        border-color: $danger;
                    }
                    &.selected {
                        &:after {
                            opacity: 1;
                        }
                    }
                    &:before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px;
                        border: 2px solid #000;
                        position: absolute;
                        top: 30px;
                        left: 23px;
                        border-radius: 50%;
                    }
                    &:after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background-color: #000;
                        position: absolute;
                        top: 35px;
                        left: 28px;
                        border-radius: 50%;
                        opacity: 0;
                        transition: 200ms;
                    }
                }
                .store-info {
                    &__title {
                        color: #878c8f;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    &__subtitle {
                        color: #0f191e;
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        line-height: 17px;
                        margin-bottom: 16px;
                    }
                    &__icon {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 15px;
                        margin-bottom: 25px;
                    }
                    &__name {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        align-items: center;
                        letter-spacing: -0.5px;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    &__brand {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        align-items: center;
                        letter-spacing: -0.5px;
                        color: #b7babc;
                        text-align: center;
                        margin-bottom: 25px;
                    }
                    &__products {
                        // margin-top: 25px;
                        &__item {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            &:not(&:last-child) {
                                padding-bottom: 20px;
                                margin-bottom: 20px;
                                border-bottom: 1px solid #eaeaea;
                            }
                            &__image {
                                width: 100px;
                                // height: 100px;
                                margin-right: 20px;
                                flex-basis: 100px;
                                flex-grow: 0;
                                flex-shrink: 0;

                                img {
                                    object-fit: contain;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            &__info {
                                width: calc(100% - 120px);
                                flex-basis: calc(100% - 120px);
                                flex-grow: 0;
                                flex-shrink: 0;
                                .name {
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 16px;
                                    line-height: 18px;
                                    /* or 150% */

                                    display: flex;
                                    align-items: center;
                                    letter-spacing: -0.1px;

                                    /* Negro */

                                    color: #0f191e;
                                    margin-bottom: 10px;
                                }
                                .quantity {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 18px;
                                    /* identical to box height, or 150% */

                                    display: flex;
                                    align-items: center;

                                    /* Negro */

                                    color: #0f191e;
                                    margin-bottom: 10px;
                                }
                                .price {
                                    font-size: 16px;
                                    line-height: 18px;
                                    display: flex;
                                    align-items: center;
                                    color: #0f191e;

                                    .base {
                                        text-decoration: line-through;
                                        margin-right: 6px;
                                    }
                                }
                            }
                            .unavailable-product-message {
                                margin-top: 8px;
                                background-color: #fbccda;
                                padding: 18px;
                                color: #0f191e;
                                font-size: 14px;
                                letter-spacing: -0.1px;
                                line-height: 20px;
                                border-radius: 12px;
                                width: 100%;
                                flex-basis: 100%;
                                flex-grow: 0;
                                flex-shrink: 0;
                                a {
                                    color: #0f191e;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    .card-footer {
                        background-color: rgba(207, 235, 247, 0.3);
                        padding: 1.25rem;
                        border-radius: 0 0 20px 20px;
                        border: none;
                        position: relative;
                        &:after {
                            content: "";
                            width: calc(100% - 70px);
                            top: 1px;
                            border-top: 1px solid #878c8f;
                            left: 35px;
                            display: block;
                            position: absolute;
                        }
                        p {
                            margin-bottom: 0;
                            color: #878c8f;
                            font-weight: 500;
                            b {
                                color: #0f191e;
                                font-weight: 500;
                            }
                        }
                    }
                    .shipping-summary {
                        h4 {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            letter-spacing: -0.1px;
                            color: #0f191e;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            letter-spacing: -0.5px;
                            color: #0f191e;
                        }
                    }

                    .open-stores-modal-btn {
                        box-shadow: none;
                        border: 1px solid #eaeaea;
                        border-radius: 10px;
                        background-color: #fff;
                        &.invalid {
                            border-color: $danger;
                        }
                        .card-body {
                            padding: 16px 13px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            a {
                                padding-right: 25px;
                                color: $mainColor;
                                font-weight: bold;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .store-shipping {
                    &__methods {
                        margin-left: -10px;
                        margin-right: -10px;
                        justify-content: flex-start;
                        display: flex;
                        // flex-direction: column;
                        .col-6 {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        &__item {
                            border: 1px solid #eaeaea;
                            background-color: #fff;
                            box-sizing: border-box;
                            border-radius: 10px;
                            padding: 0 15px 15px 15px;
                            cursor: pointer;
                            margin-bottom: 10px;
                            font-weight: bold;
                            position: relative;
                            //Remove
                            text-align: center;
                            svg {
                                flex: 22px 0px 0px;
                                width: 25px;
                                height: 25px;
                                margin-bottom: 7px;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 7px;
                            }
                            img {
                                width: 70px;
                            }

                            h5 {
                                label {
                                    background-color: #eceded;
                                    font-weight: normal;
                                    padding: 10px 12px;
                                    margin-right: 10px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                }
                                font-weight: bold;
                                font-size: 16px;
                            }

                            &.disabled {
                                cursor: not-allowed;
                                opacity: 0.6;
                            }

                            &.selected {
                                background: rgba($mainColor, 0.1);
                                border: 2px solid $mainColor;
                                box-sizing: border-box;
                            }

                            &__icon {
                                margin-bottom: 5px;
                            }
                            span {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                letter-spacing: -0.5px;
                                color: #0f191e;
                                display: block;
                            }

                            .price {
                                position: absolute;
                                top: 18px;
                                right: 18px;
                            }
                        }
                    }
                    .alternative-pickup-person {
                        span {
                            margin-right: 10px;
                        }
                        a {
                            font-weight: 500;
                            text-decoration: underline;
                            margin-right: 10px;
                        }
                    }
                    .date-picker {
                        input {
                            border: 2px solid #eaeaea;
                            box-sizing: border-box;
                            height: 57px;
                            border-radius: 8px;
                            font-size: 16px;
                        }
                        .form-icon {
                            position: absolute;
                            bottom: 20px;
                            font-size: 18px;
                            right: 25px;
                            margin: 0;
                        }
                    }
                    .address-card {
                        border: 0 !important;
                        border-radius: 8px;
                        position: relative;
                        .btn {
                            border-radius: 8px;
                            border: 0;
                            height: 28px;
                            width: 90px;
                            padding: 0;
                            font-weight: normal;
                            text-decoration: underline;
                            font-size: 16px;
                            line-height: 12px;
                            position: absolute;
                            right: -15px;
                            top: -30px;
                        }
                    }
                    .store-address-card {
                        border: 1px solid #e3e3e3 !important;
                        border-radius: 8px;
                        display: flex;
                        padding: 10px 12px;
                        background-color: #fff;
                        align-items: center;
                        &__line {
                            width: calc(100% - 40px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        .btn {
                            border-radius: 8px;
                            border: 0;
                            height: 28px;
                            width: 90px;
                            padding: 0;
                            font-weight: normal;
                            text-decoration: underline;
                            font-size: 16px;
                            line-height: 12px;
                        }
                    }
                    .shipping-days {
                        border-radius: 8px;
                        box-shadow: none;
                        margin-top: 15px;
                        padding: 10px 12px;
                        background-color: #fff;
                    }
                }
                .payment-methods {
                    .card-alert {
                        border-radius: 20px;
                        padding: 15px 20px;
                        margin-top: 20px;
                        margin-bottom: 40px;
                        text-align: center;
                        width: 100%;
                        background-color: #cdf2dc;
                    }
                    &__item {
                        background: #ffffff;
                        border: 1px solid #d9dadb;
                        box-sizing: border-box;
                        border-radius: 10px;
                        display: flex;
                        padding: 16px 16px 16px 45px;
                        cursor: pointer;
                        margin-bottom: 16px;
                        height: 65px;
                        align-items: center;
                        position: relative;
                        &:before {
                            content: "";
                            display: block;
                            width: 18px;
                            height: 18px;
                            border: 2px solid #b7babc;
                            border-radius: 50%;
                            margin-right: 12px;
                            position: absolute;
                            top: 50%;
                            left: 16px;
                            transform: translateY(-50%);
                        }
                        &.selected {
                            background: rgba(85, 172, 237, 0.1);
                            border: 2px solid #18a0fb;
                            &:before {
                                border-color: #18a0fb;
                            }
                            &:after {
                                content: "";
                                display: block;
                                width: 10px;
                                height: 10px;
                                background-color: #18a0fb;
                                border-radius: 50%;
                                margin-right: 12px;
                                position: absolute;
                                top: 50%;
                                left: 20px;
                                transform: translateY(-50%);
                            }
                        }
                        .icon {
                            margin-right: 16px;
                            display: none;
                        }
                        &__info {
                            width: 100%;
                            display: flex;
                            h4 {
                                color: #0f191e;
                                font-size: 15px;
                                font-weight: 500;
                                letter-spacing: -0.4px;
                                line-height: 17px;
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                margin-right: auto;
                                margin-bottom: 0;
                                display: flex;
                                align-items: center;
                                .total-payment {
                                    color: #0f191e;
                                    font-size: 14px;
                                    font-weight: bold;
                                    letter-spacing: -0.4px;
                                    line-height: 17px;
                                    text-align: right;
                                }
                            }
                            p {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                letter-spacing: -0.5px;
                                color: #0f191e;
                                margin-bottom: 12px;
                            }
                            .logo-ibk {
                                height: 16px;
                                margin-right: 8px;
                                &.msi {
                                    height: 36px;
                                }
                            }
                            svg {
                                height: 35px;
                                width: auto;
                            }
                            img {
                                height: 35px;
                            }
                        }
                    }
                    .section-title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.5px;
                        color: #000000;
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }

                    .form-group {
                        label {
                            svg {
                                margin-left: 10px;
                            }
                        }
                    }

                    .billing {
                        margin-bottom: 20px;
                        .custom-control {
                            &:first-child {
                                margin-bottom: 15px;
                            }
                        }
                    }

                    .credit-card {
                        .cards {
                            margin-bottom: 15px;
                        }
                        .card-logo {
                            position: absolute;
                            bottom: 18px;
                            left: 15px;
                            display: none;
                        }
                        .card-icon {
                            position: absolute;
                            bottom: 18px;
                            left: 20px;
                            display: none;
                        }
                        .cc-input {
                            .form-control {
                                padding-left: 15px;
                            }
                            &.valid {
                                .cc-input__valid {
                                    color: $success;
                                }
                            }
                            .ibk-card-ico {
                                position: absolute;
                                font-size: 20px;
                                bottom: 15px;
                                right: 20px;
                            }
                            &__valid {
                                position: absolute;
                                font-size: 20px;
                                bottom: 17px;
                                right: 20px;
                                color: #eaeaea;
                            }
                        }
                        .invalid-ibk-card {
                            small {
                                position: relative;
                                top: -20px;
                            }
                        }
                        .invoice-check {
                            margin-bottom: 20px;
                            label {
                                padding-top: 1px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: -0.1px;
                            }
                        }
                        .card-alert {
                            border-radius: 20px;
                            padding: 10px 16px;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            svg {
                                margin-right: 7px;
                            }
                            &.success {
                                background-color: #cdf2dc;
                            }
                            &.warning {
                                background-color: #ffefca;
                            }
                            &.purple {
                                background-color: #cfb2f9;
                            }
                        }
                        .divide-it-alert {
                            display: flex;
                            align-items: center;
                            background: #cfebf7;
                            border-radius: 12px;
                            padding: 16px 40px 16px 16px;
                            span {
                                margin-left: 10px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                width: calc(100% - 30px);
                            }
                        }
                    }
                    .divide-it {
                        margin-bottom: 20px;
                        .alert-box-fourth {
                        }
                        .installments-list {
                            margin-bottom: 20px;
                            label {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                letter-spacing: -0.1px;
                                color: #878c8f;
                            }
                            .card {
                                box-shadow: none;
                                border-radius: 4px !important;
                                border-width: 2px;
                                .list-group {
                                    border: none !important;
                                }
                                .btn {
                                    background: transparent !important;
                                    border: none;
                                    box-sizing: border-box;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 24px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: -0.1px;
                                    color: #000000;
                                    .fas {
                                        color: $mainColor !important;
                                        &:before {
                                            content: "\f106";
                                        }
                                    }
                                    &.collapsed {
                                        border-bottom-left-radius: 8px;
                                        border-bottom-right-radius: 8px;
                                        .fas {
                                            &:before {
                                                content: "\f107";
                                            }
                                        }
                                    }
                                }
                                .collapse,
                                .collapsing {
                                    border-top: 1px solid #eaeaea;
                                    margin-top: -2px;
                                    ul {
                                        li {
                                            background-color: #fff !important;
                                            height: 46px;
                                            a {
                                                background-color: #fff !important;
                                                align-items: center;
                                                span {
                                                    padding-left: 12px !important;
                                                    text-align: left !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .installments-disaclaimer {
                                font-size: 13px;
                                margin-top: 18px;
                                color: #000;
                                font-weight: 500;
                            }
                        }
                    }
                    .miles {
                        width: 100%;
                        background-color: #f9f9f1;
                        padding: 16px;
                        font-size: 16px;
                        font-weight: 400;
                        border-radius: 8px;
                        margin-bottom: 20px;
                        &__title {
                            display: flex;
                            align-items: center;
                            font-weight: 400;
                            font-size: 16px;
                            margin-bottom: 13px;
                            svg {
                                width: 20px;
                                height: 20px;
                                margin-right: 8px;
                            }
                        }
                        &__exchange {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            span {
                                &:last-child {
                                    white-space: nowrap;
                                    text-align: right;
                                }
                            }
                        }
                        &__total {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            font-weight: 500 !important;
                            b {
                                font-weight: 500 !important;
                            }
                            span {
                                &:last-child {
                                    white-space: nowrap;
                                    text-align: right;
                                }
                            }
                        }

                        .separator {
                            border: 1px solid #eceded;
                            margin: 20px 0;
                        }

                        .form-group {
                            max-width: 300px;
                            margin: auto;
                            h5 {
                                font-style: normal;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 19px;
                                text-align: center;
                            }
                            .input-group {
                                .btn {
                                    height: 62px;
                                    width: 90px;
                                    min-width: initial !important;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: $mainColor;
                                    border-color: #eaeaea;
                                    border-radius: 4px;
                                    font-size: 28px;
                                    background-color: #fff;
                                }
                                .form-control {
                                    border-radius: 0;
                                    text-align: center;
                                    height: 62px;
                                    border-color: #eaeaea;
                                    border-left: none;
                                    border-right: none;
                                    border-width: 1px;
                                }
                                &-prepend {
                                    .btn {
                                        border-top-right-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                }
                                &-append {
                                    .btn {
                                        border-top-left-radius: 0;
                                        border-bottom-left-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                    .payment__info {
                        .logos {
                            img {
                                width: 100px;
                                margin: 0 8px 8px 0;
                            }
                        }
                    }
                }
            }
            &__summary {
                margin-bottom: 25px;
                h4 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.1px;
                    margin-bottom: 33px;
                }
                .card {
                    border-radius: 21px;
                    border: none;
                    background-color: #f7f7f7;
                    .card-header {
                        border-radius: 20px 20px 0 0;
                        background-color: rgba(207, 235, 247, 0.3);
                        padding: 35px;
                        border: none;
                        position: relative;
                        &:after {
                            content: "";
                            width: calc(100% - 70px);
                            bottom: 1px;
                            border-top: 1px solid #878c8f;
                            left: 35px;
                            display: block;
                            position: absolute;
                        }
                        .coupon-form {
                            label {
                                color: #0f191e;
                                font-size: 16px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 19px;
                            }
                            .form-group {
                                display: flex;
                                margin: 0;
                                .form-control {
                                    box-sizing: border-box;
                                    height: 48px;
                                    width: 255px;
                                    border: 1px solid #d9dadb;
                                    border-radius: 4px;
                                    background-color: #ffffff;
                                    margin-right: 28px;
                                    font-size: 14px;
                                }
                                .btn {
                                    width: 130px;
                                    color: #ffffff;
                                    font-size: 14px;
                                    letter-spacing: 0.09px;
                                    line-height: 17px;
                                    text-align: center;
                                    height: 48px;
                                    border-radius: 8px;
                                }
                            }
                        }
                    }
                    .card-body {
                        padding: 34px;
                    }
                    .card-footer {
                        background-color: rgba(207, 235, 247, 0.3);
                        padding: 25px 32px;
                        border-radius: 0 0 20px 20px;
                        border: none;
                        position: relative;
                        &:after {
                            content: "";
                            width: calc(100% - 70px);
                            top: 1px;
                            border-top: 1px solid #878c8f;
                            left: 35px;
                            display: block;
                            position: absolute;
                        }
                        a {
                            color: $mainColor;
                            text-decoration: underline;
                        }
                    }
                }
                &__item {
                    display: flex;
                    margin-bottom: 18px;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 16px;
                    b {
                        font-weight: 500;
                        &.total-ibk {
                            color: #05be50;
                            img {
                                width: 25px;
                                margin-right: 7px;
                            }
                        }
                        &.total-plin {
                            color: #009fe3;
                            font-size: 14px;
                            img {
                                width: 25px;
                                margin-right: 7px;
                            }
                        }
                    }
                }
                &__product {
                    display: flex;
                    margin-bottom: 18px;
                    align-items: flex-start;
                    &__image {
                        width: 80px;
                        height: 80px;
                        flex: 80px 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 16px;
                        padding-top: 5px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    &__info {
                        width: calc(100% - 96px);
                        .name {
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-line-clamp: 2;
                            // line-clamp: 2;
                            // -webkit-box-orient: vertical;
                            // box-orient: vertical;
                        }
                        .name,
                        .quantity,
                        .price {
                            color: #0f191e;
                            font-family: Rubik;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 19px;
                            margin-bottom: 7px;
                        }
                        .ibk-price {
                            color: #05be50;
                            font-weight: 500;
                            img {
                                width: 25px;
                                margin-right: 7px;
                            }
                        }
                        .plin-price {
                            color: #009fe3;
                            font-weight: 500;
                            img {
                                width: 25px;
                                margin-right: 7px;
                            }
                        }
                        .seller {
                            color: #878c8f;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 19px;
                            margin-bottom: 7px;
                        }
                        .services {
                            &__item {
                                border-radius: 20px;
                                background-color: #ffffff;
                                padding: 8px 16px;
                            }
                        }
                        .unavailable-product-message {
                            margin-top: 8px;
                            background-color: #fbccda;
                            padding: 10px;
                            color: #0f191e;
                            font-size: 10px;
                            letter-spacing: -0.1px;
                            line-height: 10px;
                            border-radius: 8px;
                            a {
                                color: #0f191e;
                                text-decoration: underline;
                                display: none;
                            }
                        }
                    }
                }
                .separator {
                    border: 1px solid #eaeaea;
                    margin-bottom: 25px;
                }
                .ibk-discount {
                    background: #cdf2dc;
                    border-radius: 20px;
                    padding: 18px 16px;
                    font-size: 14px;
                    svg {
                        margin: 0 5px;
                        position: relative;
                        top: -2px;
                    }
                    b {
                        font-weight: 500;
                    }
                    & > span {
                        &:last-child {
                            padding-left: 12px;
                        }
                    }
                }
            }
        }

        /*
        * Element states
        */
        .btn {
            &[disabled]:not(.collapsed) {
                background-color: #b7babc !important;
                border-color: transparent !important;
                font-size: 0;
                &:before {
                    content: "\f1ce";
                    font-family: "Font Awesome 5 Free";
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    animation: fa-spin 2s linear infinite;
                    font-weight: 900;
                    font-size: 16px;
                    cursor: not-allowed;
                }
            }
        }

        .btn-edit {
            border-radius: 8px;
            border-width: 2px;
            height: 32px;
            width: 82px;
            padding: 0;
            font-size: 12px;
            letter-spacing: 0.09px;
            line-height: 15px;
            text-align: center;
        }

        .form-group {
            .input-error {
                display: none;
                color: $danger;
                font-weight: 500;
                font-size: 12px;
                margin-top: 4px;
            }
        }

        .invalid {
            input,
            .form-control {
                border-color: $danger !important;
            }
            &.custom-control {
                label {
                    color: $danger;
                }
            }
            .input-error {
                display: block;
            }
        }
    }
}

.contact {
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #b7babc;
    border-bottom: 1px solid #b7babc;
    display: flex;

    &__icon {
        margin-left: 0px;
        margin-right: 30px;
        img {
            width: 120px;
        }
    }
    &__info {
        width: 100%;
        h4 {
            color: #0f191e;
            font-size: 20px;
            letter-spacing: -0.5px;
            line-height: 20px;
            margin-bottom: 18px;
        }
        p {
            color: #878c8f;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 35px;
        }
        &__channels {
            padding-left: 0;
            li {
                list-style: none;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 18px;
                color: #0f191e;
                margin-bottom: 18px;
                svg {
                    margin-right: 16px;
                }
                a {
                    color: $mainColor;
                }
            }
        }
    }

    @media (max-width: 479px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
        &__icon {
            margin-bottom: 20px;
            margin-left: 0;
        }
    }
}

.copyright {
    padding-top: 20px;
    padding-bottom: 30px;
}

/*
 * Modals
 */

.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
        }
    }
}

.alternative-pickup-person-modal {
    .close-container {
        position: relative;
        height: 5px;
        .close {
            position: absolute;
            top: -12px;
            right: 4px;
            opacity: 1 !important;
        }
    }
    .visible-mobile {
        display: none;
    }
    .modal-dialog {
        .modal-content {
            .modal-header {
            }
            .modal-body {
                padding: 38px 30px;
                h3 {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                    letter-spacing: 0.004em;
                    padding: 0 25px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 50px;
                }
                h4 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.001em;
                    margin-bottom: 8px;
                }
                .form-group {
                    margin-bottom: 24px;
                    position: relative;
                    .row {
                        margin-left: -8px;
                        margin-right: -8px;
                        div[class^="col"] {
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                    }
                    label {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        letter-spacing: -0.1px;
                        color: #878c8f;
                    }
                    .form-control {
                        border: 2px solid $inputBorderColor;
                        box-sizing: border-box;
                        height: 57px;
                        border-radius: 8px;
                        font-size: 16px;

                        &.placeholder {
                            color: #878c8f;
                        }

                        &:focus {
                            border-color: $mainColor;
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        text-indent: 1px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background: transparent;
                        background: url("/select-arrow.svg");
                        background-repeat: no-repeat;
                        background-position-x: calc(100% - 20px);
                        background-position-y: 25px;
                        &:before {
                            content: "";
                            display: block;
                        }
                        &::-ms-expand {
                            display: none;
                        }
                    }

                    .btn {
                        height: 55px;
                        border-radius: 8px;
                        padding: 18px 25px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        font-size: 14px;
                        letter-spacing: 0.09px;
                        line-height: 17px;
                        text-align: center;
                        min-width: 290px;
                        max-width: 100%;
                        @media (max-width: 479px) {
                            width: 100%;
                        }
                    }
                }
                .card {
                    margin-bottom: 25px;
                    background: #f7f8f8;
                    border-radius: 8px;
                    border: none;
                    .card-body {
                        .item {
                            display: flex;
                            flex-wrap: nowrap;
                            .icon {
                                img {
                                    width: 25px;
                                    height: 25px;
                                    object-fit: contain;
                                    margin-right: 20px;
                                }
                            }
                            &__detail {
                                p {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    letter-spacing: -0.02em;
                                    margin-bottom: 8px;
                                }
                                .scheadule {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    margin-bottom: 8px;
                                    margin-top: -4px;
                                }
                                span {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: -0.02em;
                                }
                            }
                        }
                    }
                }
                .btn-main {
                    height: 56px;
                    min-width: 215px;
                    border-radius: 8px;
                }
                .alert {
                    background-color: #cfebf7;
                    display: flex;
                    border-radius: 12px;
                    &-icon {
                        height: 20px;
                        width: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $mainColor;
                        font-size: 13px;
                        margin-right: 10px;
                        flex: 20px 0px 0px;
                        margin-top: 4px;
                    }
                    &-content {
                        width: calc(100% - 30px);
                    }
                }
            }
            @media (max-width: 479px) {
                min-height: 100vh;
            }
        }
    }
    @media (max-width: 767px) {
        .visible-mobile {
            display: block;
        }
        .visible-desktop {
            display: none;
        }
        .btn-main {
            width: 100%;
        }
        .modal-dialog {
            margin: 0 !important;
            .modal-content {
                border-radius: 0;
                height: 100vh;
                .modal-body {
                    height: calc(100vh - 145px);
                    overflow-x: hidden;
                    overflow-y: scroll;
                    .close-container {
                        display: none;
                    }
                    h3 {
                        padding-top: 80px;
                        padding-left: 0;
                        text-align: left;
                        background-image: url("/user-outline.svg");
                        background-repeat: no-repeat;
                        background-position-y: 0px;
                    }
                }
                .modal-footer {
                    .btn {
                        height: 55px;
                        border-radius: 8px;
                        padding: 18px 25px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        font-size: 14px;
                        letter-spacing: 0.09px;
                        line-height: 17px;
                        text-align: center;
                        min-width: 290px;
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.payment-modal {
    .close {
        width: 30px;
        height: 30px;
        right: 15px;
        top: 25px;
        position: absolute;
        transform: rotate(45deg);
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: "";
            display: block;
            width: 2px;
            height: 25px;
            background-color: rgba(0, 0, 0, 0.6);
            transform: translate(-50%, -50%);
        }
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: "";
            display: block;
            width: 25px;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.6);
            transform: translate(-50%, -50%);
        }
    }
    &.loading-modal {
        .modal-dialog {
            max-width: 100%;
            width: 420px;
            // min-height: 100vh;
            .modal-content {
                // height: 100vh;
                border-radius: 25px;
                .modal-body {
                    .header {
                        width: 100%;
                        text-align: left;
                        margin-bottom: 20px;
                        display: none;
                        img {
                            width: 160px;
                        }
                    }
                    & > img {
                        width: 130px !important;
                        margin-bottom: 10px;
                    }
                    h4 {
                        color: #0f191e;
                        font-size: 32px;
                        letter-spacing: -1px;
                        line-height: 36px;
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    .payment-alert {
                        background-color: #cfebf8;
                        border-radius: 8px;
                        padding: 12px 15px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    p {
                        color: #0f191e;
                        font-size: 16px;
                        letter-spacing: -0.1px;
                        line-height: 24px;
                        text-align: center;
                    }
                    img {
                        width: 235px;
                    }
                    svg {
                        margin-bottom: 40px;
                    }
                    .btn {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    .modal-dialog {
        max-width: 327px;
        // margin: auto !important;
        .modal-content {
            .modal-body {
                padding: 38px 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                svg {
                    margin-bottom: 10px;
                }
                h3 {
                    font-size: 30px;
                }
                h4 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.5px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.5px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .btn {
                    height: 60px;
                    border-radius: 8px;
                    padding: 18px 25px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.1px;
                    text-align: center;
                    width: 100%;
                    &.btn-link {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        text-decoration-line: underline;
                        color: #000000;
                    }
                }
            }
        }
    }
}
.payment-modal-izi {
    .modal-dialog {
        max-width: 500px;
        height: 648px;
        border-top-right-radius: 10px;
    }
    .close {
        width: 30px;
        height: 30px;
        right: 15px;
        top: 25px;
        position: absolute;
        transform: rotate(0deg);
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    .modal-content {
        border: 1px solid #ccc;
        background: #fff;
        max-width: 500px !important;
        height: 648px !important;
        .modal-header {
            background-color: #fff;
            color: white;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            border-bottom: 0px;
            .modal-title {
                .close {
                    width: 30px;
                    height: 30px;
                    right: 10px;
                    top: 10px;
                    position: absolute;
                    padding: 0;
                    margin: 0;
                    background-color: white;
                    opacity: 1;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 16px;
                    color: #01a0e3;
                    span {
                        position: relative;
                        background-color: white;
                        font-stretch: semi-expanded;
                        font-size: 18px;
                        line-height: 16px;
                    }
                }
                h3 {
                    padding: 40px 0 15px 0;
                    font-size: 2.4rem;
                    letter-spacing: 0.05rem;
                    font-weight: 600;
                }
                h4 {
                    font-size: 2rem !important;
                }
            }
        }
        .modal-body {
            h3 {
                font-size: 30px;
                margin-bottom: 30px;
                font-weight: bold;
                color: #13161b;
            }
            p {
                line-height: 20px !important;
                font-size: 16px !important;
                margin-bottom: 30px !important;
                color: #3d3d3d !important;
            }
            .btn {
                background-color: #01a0e3;
                color: white;
                width: 60% !important;
                padding-top: 12px !important;
                padding-bottom: 12px !important;
                margin-bottom: 15px !important;
                height: auto !important;
            }
            .modal-body-footer {
                font-size: 12px;
                p {
                    margin-bottom: 0px !important;
                }
            }
        }
        .modal-footer {
            border-top: 0px;
            text-align: center;
            font-size: 12px;
            color: #646464;
            b {
                color: #13161b;
            }
            strong {
                color: #0c0c4b;
                font-weight: normal;
            }
        }
    }
    .modal-footer {
        .btn-main {
            height: 56px;
            min-width: 215px;
            border-radius: 8px;
        }
    }
}
.modal-title-h4 {
    font-size: 30px !important;
    line-height: 35px !important;
}
.payment-modal-izi.payment-ok-izi {
    .modal-dialog {
        .modal-content {
            background: #fff url("/izipay_background_ok.png") no-repeat center center !important;
            border: none;
            padding: 75px 65px;
            // background-size: cover!important;
            .modal-header {
                background-color: transparent;
                border: none;
                text-align: center;
            }
            .modal-body {
                margin: 0;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                background-color: transparent;
                background-image: none;
                padding-bottom: 0px;
                border: 0px solid #009fe3;
                height: 455px !important;
                h3 {
                    color: #009fe3;
                    font-weight: 800;
                    margin-bottom: 25px;
                    margin-top: 15px;
                }
                p {
                    text-align: center;
                    display: block;
                    font-size: 16px !important;
                    letter-spacing: 0.03rem;
                }
                p.desc-ok {
                    margin-top: 25px;
                    margin-bottom: 35px !important;
                }
            }
        }
    }
}
.pickup-points-modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                max-height: calc(100vh - 400px);
                overflow-y: scroll;
                overflow-x: hidden;
                min-height: 645px;
                h4 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 32px;
                    display: flex;
                    align-items: center;
                }
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.5px;
                    color: #0f191e;
                }

                .card {
                    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.07);
                    border-radius: 21px;
                    border: none;
                    margin-bottom: 25px;
                    .card-body {
                        padding: 25px;
                        .store-info {
                            h4 {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                letter-spacing: -0.1px;
                                color: #0f191e;
                            }
                            p {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                letter-spacing: -0.5px;
                                color: #0f191e;
                            }

                            .btn {
                                height: 60px;
                                border-radius: 8px;
                                padding: 18px 25px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                align-items: center;
                                text-align: center;
                                letter-spacing: -0.1px;
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.benefit-login-modal {
    .modal-dialog {
        max-width: 390px !important;
        .modal-content {
            max-width: 380px;
            .modal-body {
                padding: 0;
                position: relative;
                a {
                    display: block;
                    width: 45px;
                    height: 20px;
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    z-index: 100;
                    background-color: #fff;
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        height: 20px;
                        width: 1px;
                        position: absolute;
                        transform: translateX(-50%) rotate(45deg);
                        right: 0;
                        background-color: rgba(0, 0, 0, 0.7);
                        top: 0;
                    }
                    &:after {
                        transform: translateX(-50%) rotate(135deg);
                    }
                }
                iframe {
                    width: 100%;
                    height: 700px;
                    border-radius: 8px;
                }
            }
        }
    }
}

.payu-input {
    iframe {
        height: 40px;
        position: relative;
        top: 12px;
    }
}
.shps-chk-btn-info-divide {
    color: #05be50;
    border: 1px solid #05be50;
    background-color: #ffffff;
    padding: 0 5px;
    line-height: 14px;
    font-size: 12px;
    margin-left: 10px;
}
/*tooltip css*/
.shps-chk-container-tooltip {
    position: relative;
    display: inline-block;
    span.shps-chk-btn-info-divide {
        color: #05be50;
        border: 1px solid #05be50;
        background-color: #ffffff;
        padding: 0 5px;
        line-height: 14px;
        font-size: 12px;
        margin-left: 10px;
    }
    span.shps-chk-tooltip-text {
        display: none;
        visibility: hidden;
        width: auto;
        min-width: 260px;
        font-size: 14px;
        font-weight: normal;
        background-color: #cfebf7;
        color: #333;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: -15px;
        left: 140%;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    span.shps-chk-tooltip-text::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #cfebf7 transparent transparent;
    }
}
.shps-chk-container-tooltip:hover .shps-chk-tooltip-text {
    visibility: visible;
    display: block;
}
.shp-msg-plin {
    margin-top: 25px;
    background-color: #ebf4f9 !important;
    color: #0f191e;
    // font-size: 17px;
}
