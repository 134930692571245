.ps-layout {
  &__header {
    position: relative;
    padding-left: 65px;
    z-index: 1000;

    &__icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      background: $grey400;
      padding: 10px;
      border-radius: 3px;
      color: $fullWhite;
    }

    &__actions {
      strong, .btn-favorite, .user-picture, .btn {
        display: inline-block;
        position: relative;
        margin-right: 7px;
      }

      &__right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        .dropdown {
          position: static !important;
          transform: none !important;
        }
      }

      .dropdown {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        .btn {
          padding-right: 3px !important;
          padding-left: 10px !important;

          &:after {
            content: none;
          }
        }
      }
    }
  }

  &__tabset {
    background: $fullWhite;
    border: 1px solid $grey300;
    border-radius: 0.25rem;
    position: relative;

    .nav {
      margin-bottom: 1rem;

      .nav-item {
        .nav-link {
          color: $blueGrey300;
          border: none;
          border-bottom: 1px solid transparent;
          padding: 15px 25px;
          border-radius: 0;

          &.active {
            color: $secondColor;
            border-color: $secondColor;
          }
        }
      }
    }

    .tab-content {
      .tab-pane {
        padding: 1.25rem;
      }
    }

    &__actions {
      position: absolute;
      top: 5px;
      right: 5px;

      .btn {
        margin-left: 7px;
      }
    }
  }
}
