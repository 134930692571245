@import "../../styles/colors/_colors";
@import "../../styles/variables/_variables";

.App {
    &.callback-loader {
        height: 100vh;
        background: $mainColor;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
}
